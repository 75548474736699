import { get } from 'lodash-es';

import { isFormValuePresent } from '../../components/forms/formHelpers';

export function formToInputAddress(form) {
  return {
    addressLine1: get(form, 'addressLines[0]'),
    addressLine2: get(form, 'addressLines[1]'),
    addressLine3: get(form, 'addressLines[2]'),
    postalCode: form?.postalCode,
    city: form?.city,
    stateProvince: form?.stateProvince,
    country: form?.country,
  };
}

export function formToInputJobLocation(form) {
  return {
    companyName: form.companyName,
    contact: {
      name: form.contact?.name,
      email: form.contact?.email,
      phone: form.contact?.phone,
      phoneExtension: form.contact?.phoneExtension,
    },
    address: formToInputAddress(form.address),
    specialInstructions: form.specialInstructions || '',
  };
}

export function graphQLToFormJobLocation(data) {
  return {
    companyName: data.companyName,
    contact: {
      name: data.contact?.name,
      email: data.contact?.email,
      phone: data.contact?.phone,
      phoneExtension: data.contact?.phoneExtension,
    },
    address: {
      addressLines: [
        data.address?.addressLine1,
        data.address?.addressLine2,
        data.address?.addressLine3,
      ].filter(isFormValuePresent),
      postalCode: data.address?.postalCode,
      city: data.address?.city?.text,
      stateProvince:
        data.address?.stateProvince?.text || data.address?.stateProvince?.code,
      country: data.address?.country?.text || data.address?.country?.code,
    },
    specialInstructions: data.specialInstructions,
  };
}
