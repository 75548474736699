import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

import { ConfigurableCard } from '../../../components/layout/cardElements';
import { ServiceTypeField } from '../shipmentFormsCommon/shipmentServiceInformationFields';
import { FormItemSchemaDefinedSelect } from '../../../components/forms/selects';
import { PickupDateTimeField } from '../shipmentFormsCommon/shipmentDateFields';
import AddressBookControls from '../../../dialogs/shipments/AddressBookControls';
import { DangerousGoodsSection } from '../newShipment/NewShipmentServiceInformationForm';
import QuotePackageListPackages from '../packageList/QuotePackageList';
import { NoLabelForm } from '../../../components/forms/forms';
import {
  AddressLookupCard,
  NewShipmentBaseRow,
} from '../newShipment/newShipmentElements';

const NewQuoteForm = ({
  name,
  className,
  form,
  formRef,
  onValuesChange,
  initialValues,
  onEditedPackageChange,
  onSubmit,
}) => (
  <NoLabelForm
    form={form}
    formRef={formRef}
    onValuesChange={onValuesChange}
    name={name}
    className={className}
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    <Row className="flex-nowrap">
      <Col className="Flex1 spaces-vert-norm1_5" lg={24}>
        <ConfigurableCard
          titleId="book.newShipment.serviceInformation"
          bodyComponent="div"
          bodyClassName="NewShipmentCardBody NewShipment-ServiceInformationCard"
        >
          <NewShipmentBaseRow>
            <Col lg={12}>
              <NewShipmentBaseRow>
                <Col lg={24} className="JobLocationCard-Section">
                  <div className="JobLocationCard-SectionHeader">
                    <div className="JobLocationCard-SectionTitle Flex1">
                      <FormattedMessage id="book.newQuote.serviceRequested" />
                    </div>
                  </div>
                </Col>
              </NewShipmentBaseRow>
              <NewShipmentBaseRow>
                <Col lg={12}>
                  <ServiceTypeField />
                </Col>
                <Col lg={12}>
                  <FormItemSchemaDefinedSelect
                    name="commodity"
                    schemaName="serviceInformation.commodity"
                    labelId="book.newShipment.label.commodity"
                    allowFreeText
                    textAsValue
                  />
                </Col>
              </NewShipmentBaseRow>
            </Col>
            <Col lg={12}>
              <PickupDateTimeField />
            </Col>
          </NewShipmentBaseRow>
        </ConfigurableCard>

        <NewShipmentBaseRow>
          <Col lg={12}>
            <AddressLookupCard
              titleId="book.newShipment.fromAddress"
              schemaNamePrefix="origin"
              enableAddressLookup
              fieldName="addressOrigin"
              namePrefix="origin"
              showCountry
              countryCodeRestrict="US"
              showSpecialInstructions={false}
              renderHeaderExtra={type => (
                <AddressBookControls
                  name="origin"
                  type={type}
                  namePrefix="origin"
                  countryCodeRestrict="US"
                />
              )}
            />
          </Col>
          <Col lg={12}>
            <AddressLookupCard
              titleId="book.newShipment.toAddress"
              schemaNamePrefix="destination"
              enableAddressLookup
              fieldName="addressDestination"
              namePrefix="destination"
              showCountry
              countryCodeRestrict="US"
              showSpecialInstructions={false}
              renderHeaderExtra={type => (
                <AddressBookControls
                  name="destination"
                  type={type}
                  namePrefix="destination"
                  countryCodeRestrict="US"
                />
              )}
            />
          </Col>
        </NewShipmentBaseRow>
        <NewShipmentBaseRow>
          <Col lg={12}>
            <ConfigurableCard
              titleId="book.newShipment.serviceInformation"
              bodyComponent="div"
              bodyClassName="NewQuotePackagesCardBody"
            >
              <QuotePackageListPackages
                onEditedPackageChange={onEditedPackageChange}
                activeTemplateCounter
              />
            </ConfigurableCard>
          </Col>
          <Col lg={12}>
            <ConfigurableCard
              titleId="book.newShipment.dangerousGoodsDeclaration"
              bodyComponent="div"
              bodyClassName="NewShipmentCardBody NewShipment-ServiceInformationCard"
            >
              <DangerousGoodsSection noDivider />
            </ConfigurableCard>
          </Col>
        </NewShipmentBaseRow>
      </Col>
    </Row>
  </NoLabelForm>
);

export default NewQuoteForm;
