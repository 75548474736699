import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { USER_QUERY } from '../app/graphql/authQueries';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import ErrorBoundary from '../components/ErrorBoundary';
import { ThreeDotsSpinner } from '../components/data/Spinner';
import { LinksRow } from '../components/nav/navElements';
import { externalLinks } from '../config/externalLinks';
import { AccountDropdown } from './AccountDropdown';
import UserSettingsDialog from './UserSettingsDialog';
import { MetadataInfo } from './metadataWidgets';

export const accountMenuLinks = [
  { href: externalLinks.contact, textId: 'header.contactUs' },
];

export default function AccountTopBar({ leftSideContent }) {
  const { loading, data } = useQuery(USER_QUERY);
  const user = data && extractGraphqlEntity(data);

  return (
    <ErrorBoundary>
      <div className="AccountTopBar">
        <div className="left">
          {leftSideContent}
          <MetadataInfo canOpenChangelog />
        </div>
        <div className="right">
          <LinksRow
            links={[
              ...(loading
                ? [
                    <span key="loading">
                      <FormattedMessage id="account.loading" />
                      <ThreeDotsSpinner />
                    </span>,
                  ]
                : [
                    <AccountDropdown key="user" user={user} />,
                    <UserSettingsDialog key="settings" user={user} />,
                  ]),
              ...accountMenuLinks,
            ]}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}
