import { Button, Col, Row } from 'antd';
import { map, max, some } from 'lodash-es';
import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { PackageListDimensionsInputs } from './PackageListDimensionsInputs';
import { SymbolCol } from './packageListCommon';
import {
  DimensionalWeightResult,
  WeightInput,
} from './PackageListPackageEditingMode';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import { useFormContext } from '../../../components/forms/forms';
import {
  extractActualWeight,
  extractBilledWeight,
  getPackageTotal,
} from '../shipmentCommon';
import { FormItemFieldRegistration } from '../../../components/forms/basicFormElements';
import { DynamicFormSchemaContext } from '../../../components/forms/dynamic/dynamicFormSchema';
import {
  UnitSystems,
  WeightUnit,
  convertUnit,
} from '../../../app/enums/measuringUnits';
import { SettingsContext } from '../../../components/domainSpecific/settingsElements';
import { showQuoteMessage } from '../../../components/dialogs/QuoteRestrictionsMessage';

function QuoteWeightDimsRow({ index, inputProps, saveChanges, remove }) {
  const media = useResponsiveQueries();
  const symbolWidth = media.xs ? 25 : 32;
  const { formInstance, parentContext, values } = useFormContext();
  const { schema } = useContext(DynamicFormSchemaContext);
  const { unitSystem } = useContext(SettingsContext);
  const [isLoading, setIsLoading] = useState(false);

  const fieldsErrors = formInstance.getFieldsError();
  const anyErrors = some(fieldsErrors, f => some(f.errors));

  useEffect(() => {
    const allPackagesArray = map(
      parentContext?.values?.packages || [],
      (p, idx) => {
        if (idx === index) return values;
        return p;
      }
    );
    const totalWeightActual = getPackageTotal(
      allPackagesArray,
      extractActualWeight
    );
    const totalWeightBilled = getPackageTotal(
      allPackagesArray,
      extractBilledWeight
    );

    const totalLbs =
      convertUnit(
        max([totalWeightActual, totalWeightBilled]),
        UnitSystems[unitSystem].weight,
        WeightUnit.POUND
      ) || 0;
    formInstance.setFieldsValue({
      maxWeightValidator: totalLbs,
    });
    formInstance.validateFields(['maxWeightValidator']);
  }, [parentContext, formInstance, index, values, unitSystem]);

  return (
    <>
      <FormItemFieldRegistration
        name="maxWeightValidator"
        rules={schema?.packages?.maxWeightValidator?.validation?.rules}
      />
      <Row className="quote-package-weight-result">
        <div style={{ display: 'flex' }}>
          <DimensionalWeightResult
            index={index}
            separator="="
            labelId="book.newShipment.package.dims.dimensionalWeight"
            onLoadingChange={setIsLoading}
          />
        </div>
        <div className="quote-package-button">
          <Button
            type="primary"
            size="small"
            onClick={saveChanges}
            data-subject="packages"
            data-action="save"
            className="quotes-calculate-btn"
            disabled={anyErrors || isLoading}
          >
            <FAIcon icon="plus" className="icon-15" />{' '}
            <FormattedMessage id="buttons.add" />
          </Button>
          {index > 0 && (
            <FAIcon onClick={remove} icon="times-circle" className="icon-15" />
          )}
        </div>
      </Row>
      <Row align="top" className="quote-packages-inputs">
        <Col flex="3">
          <WeightInput index={index} {...inputProps} />
        </Col>
        <SymbolCol className="DimensionSeparator">
          <FAIcon icon="plus-square" className="icon-22" />
        </SymbolCol>
        <PackageListDimensionsInputs
          index={index}
          inputColProps={{ flex: '3' }}
          symbolColProps={{ style: { width: symbolWidth } }}
          inputProps={{
            ...inputProps,
            isShipment: false,
          }}
        />
      </Row>
    </>
  );
}

export function QuotePackagesEditingMode({
  index,
  saveChanges,
  hasError,
  remove,
}) {
  const { formatMessage } = useIntl();
  const { formInstance } = useFormContext();
  const errorVisible = useRef();

  const maxWeightError = formInstance.getFieldError('maxWeightValidator');
  useEffect(() => {
    if (!errorVisible.current && some(maxWeightError)) {
      errorVisible.current = true;
      showQuoteMessage(formatMessage, () => {
        errorVisible.current = false;
      });
    }
  }, [maxWeightError, formatMessage]);

  return (
    <div
      className={classNames('NewShipmentPackageListForm', {
        'has-error': hasError,
      })}
    >
      <div className="new-quote-package-header">
        <p>
          <FormattedMessage
            id="book.newQuote.packageItemHeaderWithNumber"
            values={{ index: index + 1 }}
          />
        </p>
      </div>
      <div>
        <div className="spaces-vert-md">
          <QuoteWeightDimsRow
            saveChanges={saveChanges}
            remove={remove}
            index={index}
          />
        </div>

        <div
          style={{ marginLeft: 20 }}
          className={classNames('NewShipmentPackage-ErrorMessage', {
            'has-content': some(maxWeightError),
          })}
        >
          {some(maxWeightError) && (
            <FormattedMessage id="book.newQuote.over-max-weight" />
          )}
        </div>
      </div>
    </div>
  );
}
