import { fromServerDateTimeOptional } from '../../common/utils/dateUtils';
import { normalizeAccount } from './accountConversions';

const { graphQLToFormJobLocation } = require('./locationConversions');

export function graphQLToForm(values) {
  const account = values?.metadata?.qolAccount;
  return {
    ...values,
    exceptedQuantity: values?.exceptedQty,
    pickupDateTime: fromServerDateTimeOptional(
      values?.pickupDateTime?.scheduled
    ),
    account: account ? normalizeAccount(account) : null,
    origin: graphQLToFormJobLocation(values?.origin || {}),
    destination: graphQLToFormJobLocation(values?.destination || {}),
  };
}
