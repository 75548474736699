import { pick, toPairs } from 'lodash-es';

import { toServerDateTimeOptional } from '../../common/utils/dateUtils';
import { extractStringValueFromRichFormValue } from '../../components/forms/dynamic/dynamicFormHelpers';
import { isFormSectionNonEmpty } from '../../components/forms/formHelpers';
import { richSelectValueToCodeValueInput } from '../../components/forms/selectUtils';
import { canComputeDimensionalWeight } from '../../forms/shipments/packageList/packageListCommon';
import { extractActualWeight } from '../../forms/shipments/shipmentCommon';
import { mapDeviceAddonsInput } from './deviceAddonConversions';
import { formToInputJobLocation } from './locationConversions';
import { notificationSettingsFormToGraphql } from './settingsConversions';

export const NewShipmentDateSpecialValue = {
  ASAP: 'ASAP',
  NOW: 'NOW',
  TBD: 'TBD',
};

export function applyNewShipmentDateSpecialValue(value, data) {
  if (value === NewShipmentDateSpecialValue.ASAP) {
    return { scheduled: null, isAsap: true };
  }
  if (value === NewShipmentDateSpecialValue.TBD) {
    return { scheduled: null, isAsap: false };
  }
  if (value === NewShipmentDateSpecialValue.NOW) {
    return { scheduled: data, isAsap: false };
  }
  return { isAsap: false };
}

export function formToGraphqlDangerousGoods(parentForm) {
  const exceptedQuantity = parentForm.dangerousGoods
    ? parentForm.exceptedQuantity
    : null;
  return {
    dangerousGoods: parentForm.dangerousGoods,
    exceptedQuantity,
    unNumber:
      exceptedQuantity === false
        ? parentForm.unNumber
            ?.filter(item => isFormSectionNonEmpty(item))
            ?.map(item => pick(item, ['code', 'text']))
        : null,
  };
}

function mapPackages(packages) {
  if (!packages) {
    return null;
  }

  // The first package determines weight mode (actual vs dimensional)
  const isFirstPackageDimensional = canComputeDimensionalWeight(packages[0]);

  return packages.map(pkg => ({
    packageSource: richSelectValueToCodeValueInput(pkg.packageSource),
    packagingType: richSelectValueToCodeValueInput(pkg.packagingType),
    commodityTemperature: richSelectValueToCodeValueInput(
      pkg.commodityTemperature
    ),
    packageQualificationTime: pkg.packageQualificationTime,
    packoutDateTime: toServerDateTimeOptional(pkg.packoutDateTime),
    actualWeight: extractActualWeight(pkg),
    ...(isFirstPackageDimensional
      ? { length: pkg.length, width: pkg.width, height: pkg.height }
      : {
          /* Ignore dimensions if in actual-weight mode */
        }),
    ...mapDeviceAddonsInput(pkg.deviceAddons),
    pieces: pkg.pieces?.map(piece => ({
      description: piece.description,
      partNumber: piece.partNumber,
      quantity: piece.quantity,
    })),
    coolantAddon: {
      type: richSelectValueToCodeValueInput(pkg.coolantAddon?.type),
      source: richSelectValueToCodeValueInput(pkg.coolantAddon?.source),
      amount: pkg.coolantAddon?.amount,
      replenishTime: pkg.coolantAddon?.replenishTime,
    },
  }));
}

const isHelperField = name => /(-date|-time)$/.test(name);

export function formToGraphqlShipmentInput(form) {
  const { serviceInformation } = form;
  return {
    accountNumber: form.account.number,
    origin: formToInputJobLocation(form.origin),
    destination: formToInputJobLocation(form.destination),
    serviceInformation: {
      serviceType: richSelectValueToCodeValueInput(
        serviceInformation.serviceType
      ),
      commodity: serviceInformation.commodity,
      bolNumber: serviceInformation.bolNumber,
      additionalInsurance: parseFloat(serviceInformation.additionalInsurance),
      currency: richSelectValueToCodeValueInput(serviceInformation.currency),
      declaredValue: parseFloat(serviceInformation.declaredValue),
      costCenter: serviceInformation.costCenter,
      shipperSpecificInfo: toPairs(serviceInformation.shipperSpecificInfo)
        .filter(([name]) => !isHelperField(name))
        .map(([name, value]) => ({
          name,
          value: extractStringValueFromRichFormValue(value),
        })),
      ...formToGraphqlDangerousGoods(serviceInformation),
      eoriNumber: serviceInformation.eoriNumber,
      hsInfo: serviceInformation.hsInfo
        ?.filter(item => isFormSectionNonEmpty(item))
        ?.map(item => pick(item, ['code', 'description'])),
    },
    deliveryDateTime: {
      scheduled: toServerDateTimeOptional(form.destination.deliveryDateTime),
      ...applyNewShipmentDateSpecialValue(
        form.destination['deliveryDateTime-specialValue'],
        toServerDateTimeOptional(
          form.destination['deliveryDateTime-specialValueData']
        )
      ),
    },
    pickupDateTime: {
      scheduled: toServerDateTimeOptional(form.origin.pickupDateTime),
      ...applyNewShipmentDateSpecialValue(
        form.origin['pickupDateTime-specialValue'],
        toServerDateTimeOptional(form.origin['pickupDateTime-specialValueData'])
      ),
    },
    packages: form.packageList.tbd
      ? []
      : mapPackages(form.packageList.packages),
    unitSystem: form.unitSystem,
    emailNotificationsOverride: serviceInformation.overrideEmailNotifications
      ? notificationSettingsFormToGraphql(serviceInformation.notifications)
      : null,
  };
}
