import { Button, Card, Col, Row, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { map } from 'lodash-es';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router';

import { AsyncCallbackButton } from '../../../components/data/asyncActionElements';
import { TwoLinesSeparator } from '../../../components/layout/layoutElements';
import { InlineLink } from '../../../components/typography';
import ViewQuoteBreakdownDialog from '../../../dialogs/ViewQuoteBreakdownDialog';
import routes from '../../../app/routes';
import { YesNoFormat } from '../../../components/forms/checkable';
import { CurrencyFormat } from '../../../components/domainSpecific/currencyElements';
import { QuoteConversionDialog } from '../../../dialogs/QuoteConversion';
import { WeightFormat } from '../../../components/domainSpecific/measuringUnitsElements';

function QuoteButtons({ onSubmit, referenceNo }) {
  const { push } = useHistory();

  const onDismissClick = () => {
    push(generatePath(routes.monitor));
  };

  return (
    <div className="quote-buttons-container">
      {referenceNo && (
        <Button
          className="quote-button save-button"
          type="secondary"
          onClick={() => {
            push(
              generatePath(routes.quoting.editQuote, {
                referenceNo,
              })
            );
          }}
        >
          <FormattedMessage id="quote.review" />
        </Button>
      )}

      <AsyncCallbackButton
        className="quote-button continue-button"
        type="primary"
        callback={onSubmit}
      >
        <FormattedMessage id="book.newQuote.continue" />
      </AsyncCallbackButton>

      <AsyncCallbackButton
        className="quote-button cancel-button"
        type="default"
        onClick={onDismissClick}
      >
        <FormattedMessage id="buttons.dismiss" />
      </AsyncCallbackButton>
    </div>
  );
}

export function QuoteSummaryHeader({ referenceNo, quote }) {
  const [isBreakdownDialogOpen, setBreakdownDialogOpen] = useState(false);
  const [isConversionDialogOpen, setConversionDialogOpen] = useState(false);
  const { push } = useHistory();

  // This callback is triggered when the "Continue to booking" button is clicked.
  const handleContinue = useCallback(async () => {
    setConversionDialogOpen(true);
  }, []);

  // When the red OK button is clicked, redirect to the New Shipment page
  // and pass along the quote data to prefill the shipment form.
  const handleConversionOk = useCallback(() => {
    setConversionDialogOpen(false);
    push(`/book/new-shipment/from-address?referenceNo=${referenceNo}`);
  }, [push, referenceNo]);

  return (
    <div>
      <Card className="quote-summary-header">
        <Row className="header-row" align="middle" justify="space-between">
          <Col>
            <Typography.Title level={1}>
              <FormattedMessage id="book.newQuote.resultTitle" />
            </Typography.Title>
            {referenceNo && (
              <div style={{ display: 'flex' }}>
                <div className="quote-id-label">
                  <FormattedMessage id="book.quote.quoteId" />
                  <span className="quote-id">{referenceNo}</span>
                </div>
                <div className="quote-id-label">
                  <span className="quote-id">
                    <span style={{ marginRight: 3 }}>|</span>
                    <FormattedMessage id="book.newQuote.expires" />{' '}
                    <span>
                      {moment(quote?.expiryDateTime)
                        .format('DDMMMYYYY')
                        .toUpperCase()}
                    </span>
                  </span>
                </div>
              </div>
            )}
          </Col>
          <Col span={10}>
            <Row className="details-grid" gutter={[16, 16]}>
              <Col
                className="details-grid-item details-grid-item-first"
                span={8}
              >
                <span className="label">
                  <FormattedMessage id="shipmentDetail.service" />
                </span>
                <span className="value">{quote?.serviceType?.text}</span>
              </Col>
              <Col
                className="details-grid-item details-grid-item-second"
                span={8}
              >
                <span className="label">
                  <FormattedMessage id="book.newShipment.label.commodity" />
                </span>
                <span className="value">{quote?.commodity}</span>
              </Col>
              <Col
                className="details-grid-item details-grid-item-last"
                span={8}
              >
                <span className="label">
                  <FormattedMessage id="book.newShipment.label.dangerousGoods" />
                </span>
                <span className="value">
                  <YesNoFormat
                    className="text-uppercase"
                    value={quote?.dangerousGoods}
                  />
                </span>
              </Col>
            </Row>
            <TwoLinesSeparator className="full-width-separator" />

            <Row className="details-grid" gutter={[16, 16]}>
              <Col
                className="details-grid-item  details-grid-item-first"
                span={8}
              >
                <span className="label">
                  <FormattedMessage id="quoteDetail.pieces" />
                </span>
                <span className="value">{quote?.pieces}</span>
              </Col>
              <Col
                className="details-grid-item details-grid-item-second"
                span={8}
              >
                <span className="label">
                  <FormattedMessage id="quoteDetail.billWeight" />
                </span>
                <span className="value">
                  <WeightFormat value={quote?.weight} />
                </span>
              </Col>
              {quote?.dangerousGoods && (
                <Col
                  className="details-grid-item details-grid-item-last"
                  span={8}
                >
                  {map(quote?.unNumber, ({ code, text }) => (
                    <div key={code} style={{ display: 'flex' }}>
                      <span className="details-grid-item-dg-number">
                        <FormattedMessage id="quoteDetail.unNumber.short" />{' '}
                        {code}
                      </span>
                      <span className="details-grid-item-dg-desc">{text}</span>
                    </div>
                  ))}
                </Col>
              )}
            </Row>
            <TwoLinesSeparator className="full-width-separator" />
          </Col>
          <Col>
            <Typography.Title level={1} className="price">
              <FormattedMessage id="book.newQuote.yourPrice" /> |{' '}
              <CurrencyFormat value={quote?.quoteTotal} />
            </Typography.Title>
            <InlineLink onClick={() => setBreakdownDialogOpen(true)}>
              <FormattedMessage id="book.quote.quoteBreakdown" />
            </InlineLink>
          </Col>
        </Row>
      </Card>

      <QuoteButtons onSubmit={handleContinue} referenceNo={referenceNo} />

      <ViewQuoteBreakdownDialog
        quote={quote}
        isOpen={isBreakdownDialogOpen}
        close={() => setBreakdownDialogOpen(false)}
      />

      <QuoteConversionDialog
        isOpen={isConversionDialogOpen}
        onOk={handleConversionOk}
        onCancel={() => setConversionDialogOpen(false)}
      />
    </div>
  );
}
