import routes from '../app/routes';
import {
  newQuoteGuard,
  newShipmentGuard,
} from '../components/guards/pageGuards';
import TopMenu from '../components/menus/TopMenu';

const TOP_MENU_ITEMS = [
  { to: routes.monitor, labelId: 'menu.monitor' },
  { to: routes.quoting.newQuote, labelId: 'menu.quote', guard: newQuoteGuard },
  {
    to: routes.book.newShipment,
    labelId: 'menu.book',
    guard: newShipmentGuard,
  },
];

export const MainTopMenu = props => (
  <TopMenu {...props} definitions={TOP_MENU_ITEMS} />
);
