import { Col, Tooltip } from 'antd';
import { compact, every, property, some } from 'lodash-es';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useReduxDialogControls } from '../../../common/utils/dialogUtils';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import { FormItemInputLength } from '../../../components/domainSpecific/measuringUnitsElements';
import {
  makeRulesAddingDecorator,
  makeRulesReplacingDecorator,
} from '../../../components/forms/FormItem';
import {
  isFormSectionNonEmpty,
  isFormValuePresent,
  useValidationAfterChange,
} from '../../../components/forms/formHelpers';
import { useFormContext } from '../../../components/forms/forms';
import { SymbolCol, useIsFirstPackageDimensional } from './packageListCommon';

const DIM_FIELDS = ['length', 'width', 'height'];

function useValidationRules({
  outerRules,
  index,
  disabled,
  isShipment = true,
}) {
  const isFirstPackageDimensional = useIsFirstPackageDimensional();

  useValidationAfterChange({
    dependencyName: 'length',
    dependentNames: ['width', 'height'],
  });
  useValidationAfterChange({
    dependencyName: 'width',
    dependentNames: ['length', 'height'],
  });
  useValidationAfterChange({
    dependencyName: 'height',
    dependentNames: ['length', 'width'],
  });
  const { formInstance } = useFormContext();

  const integerValidator = (_, value) => {
    if (value !== undefined && value !== null && value !== '') {
      const numericValue = Number(value);
      if (!Number.isInteger(numericValue)) {
        return Promise.reject(new Error('Please enter a whole number'));
      }
    }
    return Promise.resolve();
  };

  const withAddedRule = makeRulesAddingDecorator({
    outerRules,
    newRules: compact([
      // 2-nd and later packages - static required rule if in dimensional mode
      index > 0 && isFirstPackageDimensional && { required: true },
      // Always validate that any provided value is an integer
      { validator: integerValidator },
      // 1-st package - dynamic required: all or none
      index === 0 &&
        isShipment && {
          message: (
            <FormattedMessage
              id="error.allOrNone"
              values={{
                infix: (
                  <FormattedMessage id="book.newShipment.package.dims.dimensions" />
                ),
              }}
            />
          ),
          validator: _ => {
            // All or none dimensions entered
            const presence = DIM_FIELDS.map(name =>
              isFormValuePresent(formInstance.getFieldValue(name))
            );
            if (!every(presence) && some(presence)) {
              return Promise.reject(new Error());
            }

            return Promise.resolve();
          },
        },
    ]),
  });

  const withDisabledRequired = makeRulesReplacingDecorator({
    outerRules,
    predicate: property('required'),
    replacement: { required: false },
  });

  return disabled ? withDisabledRequired : withAddedRule;
}

export function useConsistentWeightModeDialog() {
  const isFirstPackageDimensional = useIsFirstPackageDimensional();
  const { open, isOpen, close } = useReduxDialogControls({
    id: 'newShipment.packages.consistentWeightMode',
  });

  return {
    onAddPackage: useCallback(
      ({ index, value }) => {
        if (
          index > 0 &&
          isFirstPackageDimensional &&
          !isFormSectionNonEmpty(value) // This is to disable dialog on copy package
        ) {
          open();
        }
      },
      [isFirstPackageDimensional, open]
    ),
    isOpen,
    close,
  };
}

function DimensionInputWrapper({ index, children }) {
  const isFirstPackageDimensional = useIsFirstPackageDimensional();

  // Show tooltip for nin-first actual weight packages
  return index > 0 && !isFirstPackageDimensional ? (
    <Tooltip
      title={
        <FormattedMessage
          id="book.newShipment.packageDialogs.weightModeConsistencySpecific.text"
          values={{
            infix: (
              <FormattedMessage id="book.newShipment.package.dims.actualWeight" />
            ),
          }}
        />
      }
    >
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
}

export function PackageListDimensionsInputs({
  index,
  inputColProps = { className: 'Flex1' },
  symbolColProps = {},
  inputProps: {
    rules: outerRules,
    disabled: outerDisabled,
    isShipment,
    ...inputPropsRest
  } = {},
}) {
  const isFirstPackageDimensional = useIsFirstPackageDimensional();
  const disabled = outerDisabled || (!isFirstPackageDimensional && index > 0);
  const rules = useValidationRules({ outerRules, index, disabled, isShipment });

  return (
    <>
      <Col {...inputColProps}>
        <DimensionInputWrapper index={index}>
          <FormItemInputLength
            name="length"
            labelId="book.newShipment.label.length"
            schemaName="packages.length"
            rules={rules}
            disabled={disabled}
            {...inputPropsRest}
          />
        </DimensionInputWrapper>
      </Col>
      <SymbolCol className="DimensionSeparator" {...symbolColProps}>
        <FAIcon icon="times" className="icon-16" />
      </SymbolCol>
      <Col {...inputColProps}>
        <DimensionInputWrapper index={index}>
          <FormItemInputLength
            name="width"
            labelId="book.newShipment.label.width"
            schemaName="packages.width"
            rules={rules}
            disabled={disabled}
            {...inputPropsRest}
          />
        </DimensionInputWrapper>
      </Col>
      <SymbolCol className="DimensionSeparator" {...symbolColProps}>
        <FAIcon icon="times" className="icon-16" />
      </SymbolCol>
      <Col {...inputColProps}>
        <DimensionInputWrapper index={index}>
          <FormItemInputLength
            name="height"
            labelId="book.newShipment.label.height"
            schemaName="packages.height"
            rules={rules}
            disabled={disabled}
            {...inputPropsRest}
          />
        </DimensionInputWrapper>
      </Col>
    </>
  );
}
