import { TwoLinesSeparator } from '../../../../components/layout/layoutElements';

export default function AddressDisplay({ address }) {
  const { addressLine1, addressLine2, city, stateProvince, postalCode } =
    address || {};
  return (
    <>
      {addressLine1 && (
        <>
          {addressLine1}
          <TwoLinesSeparator />
        </>
      )}
      {addressLine2 && (
        <>
          {addressLine2}
          <TwoLinesSeparator />
        </>
      )}
      {city.text}, {stateProvince.code} {postalCode}
    </>
  );
}
