import { message } from 'antd';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { filter, find, startsWith } from 'lodash-es';
import { Prompt, generatePath, useHistory, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';

import BaseLoggedPage from '../templates/BaseLoggedPage';
import NewQuoteForm from '../forms/shipments/quotes/newQuoteForm';
import { DynamicFormDependenciesProvider } from '../components/forms/dynamic/dynamicFormDependencies';
import useNewQuoteFormState from '../forms/shipments/quotes/useNewQuoteFormState';
import { OverrideSettingsContextProvider } from '../components/domainSpecific/settingsElements';
import { DataStateIndicatorGuard } from '../components/data/dataStateHandlers';
import { FormSanitizationProvider } from '../components/forms/FormSanitizationContext';
import { useAccounts } from '../hooks/data/auth';
import { AccountSelectionDialog } from '../dialogs/shipments/newShipmentDialogs';
import NewShipmentFormSchemaProvider from '../forms/shipments/newShipment/NewShipmentFormSchemaProvider';
import { AsyncCallbackButton } from '../components/data/asyncActionElements';
import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { FlexCol, TitleWithExtra } from '../components/layout/layoutElements';
import ProcessingModal from '../components/dialogs/ProcessingModal';
import { CancelLink } from '../forms/shipments/newShipment/newShipmentElements';
import { PageExitDialogType } from './nav/PageExitDialog';
import routes from '../app/routes';
import {
  CREATE_QUOTE_MUTATION,
  QUOTE_DETAIL_QUERY,
} from '../app/graphql/quoteQueries';
import {
  showQuoteMessage,
  showRetrieveQuoteMessage,
} from '../components/dialogs/QuoteRestrictionsMessage';
import { InnerContentScrollbars } from '../components/layout/InnerContentScrollbars';
import { graphQLToForm } from '../app/data/quoteConversion';
import { useFlashMessageContext } from '../components/dialogs/FlashMessageProvider';
import { formToGraphqlQuoteInput } from '../app/data/newQuoteConversions';
import { newQuoteGuard } from '../components/guards/pageGuards';
import { withPageGuard } from '../components/guards/PageGuard';
import ActiveAccountHeader from '../widgets/ActiveAccountHeader';

function useQuoteSubmitState({
  mutation,
  mutationOptions, // = PREPARE_SHIPMENT_QUERY,
}) {
  const [mutate, { loading }] = useMutation(mutation, mutationOptions);
  const { push } = useHistory();
  const { errorMessage } = useFlashMessageContext();

  async function submit(values) {
    try {
      const { data } = await mutate({
        variables: {
          input: formToGraphqlQuoteInput(values),
        },
      });
      push(
        generatePath(routes.quoting.result, {
          referenceNo: data?.createQuote?.referenceNo,
        }),
        { newQuote: true }
      );
    } catch (err) {
      errorMessage(err);
    }
  }

  return {
    submit,
    isLoading: loading,
  };
}

function NewQuoteExitPrompt({ account, skip }) {
  return (
    <Prompt
      when={!!account && !skip}
      message={({ pathname }) =>
        startsWith(pathname, routes.quoting.newQuote)
          ? true
          : PageExitDialogType.CANCEL_QUOTE
      }
    />
  );
}

const NewQuoteButtons = ({ onSubmit, buttonProps, messageId }) => (
  <AsyncCallbackButton
    type="primary"
    data-subject="shipment"
    data-action="save"
    callback={onSubmit}
    loadingTextId="buttons.validating"
    {...buttonProps}
  >
    <span>
      <FormattedMessage id={messageId} />
    </span>
    <FAIcon icon="chevron-right" />
  </AsyncCallbackButton>
);

const NewQuotePageInner = () => {
  const accountsResult = useAccounts();
  const { account, setAccount } = useNewQuoteFormState();

  const quoteEnabledAccounts = filter(
    accountsResult.customerAccounts,
    acc => acc.newQuote?.enabled
  );
  return (
    <DataStateIndicatorGuard
      queryResult={{
        ...accountsResult,
        data: quoteEnabledAccounts,
      }}
    >
      {() => (
        <>
          <AccountSelectionDialog
            value={account}
            onChange={setAccount}
            customerAccounts={quoteEnabledAccounts}
            titleId="book.newQuote.accountDialog.title"
            filterFunc={a => a.newQuote.enabled}
          />
          <QuotePageBase
            title={
              <>
                <FormattedMessage id="book.newQuote.title" />
                <CancelLink
                  className="hide-md-and-smaller"
                  messageId="book.newQuote.cancelQuote"
                />
              </>
            }
            initialAccount={account}
            initialValues={{
              origin: {
                address: { country: 'United States' },
              },
              destination: {
                address: { country: 'United States' },
              },
            }}
          />
        </>
      )}
    </DataStateIndicatorGuard>
  );
};

export const EditQuotePage = () => {
  const match = useRouteMatch();
  const accountsResult = useAccounts();
  const { formatMessage } = useIntl();
  const { referenceNo } = match.params;
  const [formDirty, setFormDirty] = useState(false);

  const quoteDetailResult = useQuery(QUOTE_DETAIL_QUERY, {
    variables: {
      referenceNo,
    },
  });
  const { data: quoteDetail } = quoteDetailResult;
  const formValues = graphQLToForm(quoteDetail?.getCustomerQuote);

  const quoteAccount = find(
    accountsResult.customerAccounts,
    acc => acc.number === formValues.account?.number
  );

  useEffect(() => {
    let key;
    if (referenceNo) {
      key = showRetrieveQuoteMessage(formatMessage);
    }

    return () => {
      if (key) message.destroy(key);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage]);

  return (
    <DataStateIndicatorGuard
      queryResult={{
        ...quoteDetailResult,
        data: quoteDetail,
      }}
    >
      {() => (
        <QuotePageBase
          submitDisabled={!formDirty}
          onValuesChange={() => {
            setFormDirty(true);
          }}
          isEditMode
          title={
            <>
              <div>
                <FormattedMessage id="book.quote.reuseQuote" />
                <p style={{ fontSize: 12, lineHeight: 1, margin: 0 }}>
                  <span className="text-uppercase edit-quote-label">
                    <FormattedMessage id="book.quote.quoteId" />
                  </span>{' '}
                  <span className="edit-quote-id">{referenceNo}</span>
                </p>
              </div>
              <CancelLink
                className="hide-md-and-smaller edit-quote-cancel"
                messageId="book.newQuote.cancelQuote"
              />
            </>
          }
          submitMessageId={
            referenceNo ? 'quote.create' : 'book.newShipment.getQuote'
          }
          initialAccount={quoteAccount}
          initialValues={{
            ...formValues,
            origin: formValues.origin,
            destination: formValues.destination,
          }}
        />
      )}
    </DataStateIndicatorGuard>
  );
};

const QuotePageBase = ({
  initialAccount = null,
  initialValues,
  title,
  mutation = CREATE_QUOTE_MUTATION,
  submitMessageId = 'book.newShipment.getQuote',
  onValuesChange,
  submitDisabled = false,
  isEditMode = false,
}) => {
  const { formatMessage } = useIntl();
  const [messageShown, setMessageShown] = useState(false);

  const {
    form,
    refreshValues,
    account,
    setAccount,
    unitSystem,
    setUnitSystem,
    values,
  } = useNewQuoteFormState(initialAccount);

  useEffect(() => {
    if (initialAccount !== account) {
      setAccount(initialAccount);
    }
  }, [initialAccount, setAccount, account]);

  useEffect(() => {
    if (initialValues?.unitSystem && initialValues?.unitSystem !== unitSystem) {
      setUnitSystem(initialValues?.unitSystem);
    }
  }, [initialValues, setUnitSystem, unitSystem]);

  const { submit, isLoading } = useQuoteSubmitState({
    mutation,
  });

  useEffect(() => {
    let key;
    if (account && !messageShown && !isEditMode) {
      key = showQuoteMessage(formatMessage);
      setMessageShown(true);
    }

    return () => {
      if (key) message.destroy(key);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, formatMessage]);

  const onSubmit = formValues => {
    submit({ ...formValues, account, unitSystem });
  };

  const onSubmitCallback = () => form?.formRef?.current?.triggerSubmit();

  return (
    <BaseLoggedPage
      id="NewQuotePage"
      className={classNames('height-extending-error')}
      leftSideContent={<ActiveAccountHeader account={account} />}
    >
      <>
        {account && (
          <NewShipmentFormSchemaProvider account={account} context="QUOTE">
            <TitleWithExtra
              rightColClassName="hide-md-and-smaller"
              title={title}
            >
              <div className="NewShipmentTopControls quote-submit-button">
                <NewQuoteButtons
                  onSubmit={onSubmitCallback}
                  messageId={submitMessageId}
                  buttonProps={{
                    disabled: submitDisabled,
                  }}
                />
              </div>
            </TitleWithExtra>
            <NewQuoteExitPrompt account={account} />
            <DynamicFormDependenciesProvider values={values}>
              <FlexCol className="Flex1">
                <OverrideSettingsContextProvider unitSystem={unitSystem}>
                  <FormSanitizationProvider>
                    <InnerContentScrollbars>
                      <NewQuoteForm
                        onValuesChange={onValuesChange}
                        name="newQuoteForm"
                        form={form?.form}
                        formRef={form?.formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onEditedPackageChange={(changed, allValues) =>
                          requestAnimationFrame(() =>
                            refreshValues({ packages: allValues })
                          )
                        }
                      />
                    </InnerContentScrollbars>
                  </FormSanitizationProvider>
                </OverrideSettingsContextProvider>
              </FlexCol>
              {isLoading && (
                <ProcessingModal textId="book.newQuote.optimizing-routing" />
              )}
            </DynamicFormDependenciesProvider>
          </NewShipmentFormSchemaProvider>
        )}
      </>
    </BaseLoggedPage>
  );
};

export const NewQuotePage = withPageGuard(newQuoteGuard)(NewQuotePageInner);
