import { Col, Row } from 'antd';
import { once } from 'lodash-es';
import moment from 'moment';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useDialogControls } from '../common/utils/dialogUtils';
import Whitespace from '../components/Whitespace';
import CollectDebugReportButton from '../components/debug/CollectDebugReportButton';
import { InlineLink } from '../components/typography';
import { config } from '../config/config';
import { UserControlledChangelogDialog } from '../dialogs/ChangelogDialog';
import { useMetadata } from '../hooks/data/misc';
import { log } from '../utils/logger';

const VersionNumberLabel = ({ title }) => (
  <span title={title}>
    <FormattedMessage id="labels.version" />
    <Whitespace />
    {config.version}
  </span>
);

function VersionNumberWithLink({ title }) {
  const { isOpen, open, close } = useDialogControls();
  return (
    <>
      <UserControlledChangelogDialog isOpen={isOpen} close={close} />
      <InlineLink onClick={open}>
        <VersionNumberLabel title={title} />
      </InlineLink>
    </>
  );
}

export function VersionNumber({ canOpenChangelog, title }) {
  return canOpenChangelog ? (
    <VersionNumberWithLink title={title} />
  ) : (
    <VersionNumberLabel title={title} />
  );
}

function MockInfo({ metadata }) {
  return metadata?.isMock ? ' (MOCK)' : null;
}

function DebugInfo({ canOpenChangelog }) {
  const date = moment
    .unix(config.commitTimestamp)
    .format('YYYY-MM-DD HH:mm UTC');
  return (
    <Col>
      <CollectDebugReportButton />
      <Whitespace />
      <VersionNumber canOpenChangelog={canOpenChangelog} title={date} />
    </Col>
  );
}

const logAppInfoOnce = once(metadata =>
  log.info('App info', {
    version: config.version,
    mock: metadata.isMock,
    logLevel: config.logLevel,
    commitHash: config.commitHash,
    commitTimestamp: config.commitTimestamp,
  })
);

export function MetadataInfo({ canOpenChangelog }) {
  const metadata = useMetadata();
  useEffect(() => {
    if (!metadata) {
      return;
    }
    logAppInfoOnce(metadata);
  }, [metadata]);
  return (
    <Row className="MetadataInfo">
      <MockInfo metadata={metadata} />
      {config.showDebugInfo ? (
        <DebugInfo canOpenChangelog={canOpenChangelog} />
      ) : (
        <VersionNumber canOpenChangelog={canOpenChangelog} />
      )}
    </Row>
  );
}
