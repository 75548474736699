import { isArray, merge, mergeWith } from 'lodash-es';

import {
  SHIPMENT_FORM_AUTOFILL_QUERY,
  SHIPMENT_FORM_GET_OPTIONS_QUERY,
  SHIPMENT_FORM_SCHEMA_QUERY,
  VALIDATE_SHIPMENT_FORM_FIELD_QUERY,
} from '../../../app/graphql/jobFormQueries';
import { DynamicFormSchemaProvider } from '../../../components/forms/dynamic/dynamicFormSchema';

const REQUIRED = { validation: { rules: { required: true } } };
const NOT_REQUIRED = { validation: { rules: { required: false } } };

function mergeWithArrays(oldValues, newValues) {
  return mergeWith({}, oldValues, newValues, (_, b) =>
    isArray(b) ? b : undefined
  );
}

function removeFieldDependencies(packagesSchema) {
  return mergeWithArrays(packagesSchema, {
    autofill: {
      dependsOn: packagesSchema?.autofill?.dependsOn?.filter(
        dep => dep !== 'packages.packagingType'
      ),
      listenTo: [],
    },
  });
}

function overrideShipmentSchema(schema) {
  const actualWeight = removeFieldDependencies(schema.packages.actualWeight);
  const height = removeFieldDependencies(schema.packages.height);
  const length = removeFieldDependencies(schema.packages.length);
  const width = removeFieldDependencies(schema.packages.width);
  const newSchema = mergeWithArrays(schema, {
    serviceInformation: {
      dangerousGoods: REQUIRED,
      exceptedQuantity: REQUIRED,
      unNumber: REQUIRED,
    },
    packages: {
      actualWeight,
      height,
      length,
      width,
    },
  });
  return newSchema;
}

function overrideQuoteSchema(schema) {
  const contactInfo = {
    companyName: NOT_REQUIRED,
    contact: {
      name: NOT_REQUIRED,
      phone: NOT_REQUIRED,
    },
  };

  return merge({}, schema, {
    serviceInformation: {
      dangerousGoods: REQUIRED,
      exceptedQuantity: REQUIRED,
      unNumber: REQUIRED,
    },
    origin: {
      ...contactInfo,
    },
    destination: {
      ...contactInfo,
    },
    packages: {
      actualWeight: REQUIRED,
      height: REQUIRED,
      length: REQUIRED,
      width: REQUIRED,
      maxWeightValidator: {
        validation: {
          rules: [
            {
              type: 'number',
              max: 50,
              message: ' ',
            },
          ],
        },
      },
    },
  });
}

export default function NewShipmentFormSchemaProvider({
  account,
  children,
  context,
}) {
  return (
    <DynamicFormSchemaProvider
      query={SHIPMENT_FORM_SCHEMA_QUERY}
      optionsQuery={SHIPMENT_FORM_GET_OPTIONS_QUERY}
      fieldValidationQuery={VALIDATE_SHIPMENT_FORM_FIELD_QUERY}
      autofillQuery={SHIPMENT_FORM_AUTOFILL_QUERY}
      variables={account && { accountNumber: account.number }}
      skip={!account}
      modifySchema={modifySchema =>
        context === 'QUOTE'
          ? overrideQuoteSchema(modifySchema)
          : overrideShipmentSchema(modifySchema)
      }
      queryName="newShipmentAsyncFieldValidation"
      clearCache
    >
      {children}
    </DynamicFormSchemaProvider>
  );
}
