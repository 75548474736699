import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { SimpleConfirmDialog } from '../components/dialogs/SimpleConfirmDialog';

export function QuoteConversionDialog({ isOpen, onOk, onCancel }) {
  return (
    <SimpleConfirmDialog
      className="quote-conversion-dialog"
      titleId="book.quote.continueQuote.dialog.title"
      okTextId="buttons.ok"
      textId="book.quote.continueQuote.dialog.text"
      open={isOpen}
      onOk={onOk}
      onClose={onCancel}
      mask
      maskClosable
      centered
    >
      <Typography.Text className="nl-as-newline">
        <FormattedMessage id="book.quote.continueQuote.dialog.additionalInformation" />
      </Typography.Text>
    </SimpleConfirmDialog>
  );
}
