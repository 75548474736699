import { fromServerDateTimeOptional } from '../../common/utils/dateUtils';
import { trimWords } from '../../common/utils/stringUtils';
import {
  graphqlToDetailDateTimeSchedule,
  mapMetadata,
} from './shipmentConversions';

function graphqlToDetail(
  {
    metadata,
    quoteNumber,
    referenceNo,
    origin,
    destination,
    quoteStatus,
    stage,
    shippersReferences,
    pickupDateTime,
    deliveryDateTime,
    expiryDateTime,
    badge,
  },
  { customerAccounts } = {}
) {
  return {
    metadata: mapMetadata(metadata, { customerAccounts }),
    quoteNumber,
    referenceNo,
    origin,
    destination,
    status: quoteStatus,
    stage,
    badge,
    shipperReference: trimWords(shippersReferences || []).join('\n'),
    pickupDateTime: graphqlToDetailDateTimeSchedule(pickupDateTime),
    deliveryDateTime: graphqlToDetailDateTimeSchedule(deliveryDateTime),
    expiryDateTime: fromServerDateTimeOptional(expiryDateTime),
  };
}

export const quoteConversions = {
  graphqlToDetail,
};
