import { compact, first, last, map, size } from 'lodash-es';

import { FAIconChars } from '../../../../components/adapters/fontAwesomeAdapters';
import {
  iconOnlyLabel,
  imgOnlyLabel,
  planeRoute,
} from '../../../../components/adapters/googleMap/googleMapElements';
import {
  makeMarker,
  makePolyline,
} from '../../../../components/adapters/googleMap/googleMapHelpers';
import { MapCard } from '../../../../components/maps/MapCard';
import { cssVariables } from '../../../../styles/cssVariables';
import { getDirection, mapSize } from '../../../../utils/mapUtils';
import airplaneChangeImg from '../../../../resources/img/icons/airplane-change.png';
import GoogleMapAdapter from '../../../../components/adapters/GoogleMapAdapter';

const CLUSTERER_PROPS = {
  enable: true,
};

function originMarker(coords, direction) {
  return makeMarker(
    coords,
    iconOnlyLabel(
      FAIconChars.PLANE_DEPARTURE,
      undefined,
      cssVariables['heading-2-size'],
      `quote-plane-direction-${direction === -1 ? 'left' : 'right'}`
    )
  );
}
function destinationMarker(coords, direction) {
  return makeMarker(
    coords,
    iconOnlyLabel(
      FAIconChars.PLANE_ARRIVAL,
      undefined,
      cssVariables['heading-3-size'],
      `quote-plane-direction-${direction === -1 ? 'left' : 'right'}`
    )
  );
}

function airplaneChangeMarker(coords) {
  return makeMarker(coords, imgOnlyLabel(airplaneChangeImg, mapSize(52, 43)));
}

function extractMarkersAndPolylines(locationData) {
  const firstFlight = first(locationData.flights);
  const lastFlight = last(locationData.flights);
  const origCoords = firstFlight?.originAirportLocation;
  const destCoords = lastFlight?.destinationAirportLocation;

  const direction = getDirection(origCoords, destCoords);

  const markers = compact([
    origCoords && { ...originMarker(origCoords, direction), key: 'orig' },
    destCoords && { ...destinationMarker(destCoords, direction), key: 'dest' },
  ]);

  for (let i = 1; i < size(locationData.flights); i++) {
    markers.push({
      ...airplaneChangeMarker(locationData.flights[i].originAirportLocation),
      key: locationData.flights[i].originAirport,
    });
  }

  const polylines = map(locationData.flights, f =>
    makePolyline(
      [f.originAirportLocation, f.destinationAirportLocation],
      planeRoute()
    )
  );
  return { markers, polylines };
}

function QuoteDetailMapWithData({ locationData }) {
  const markerProps = extractMarkersAndPolylines(locationData);
  return <GoogleMapAdapter {...markerProps} clustererProps={CLUSTERER_PROPS} />;
}

const defaultRenderMap = mapEl => <MapCard>{mapEl}</MapCard>;
export default function QuoteDetailMap({
  quote,
  renderMap = defaultRenderMap,
}) {
  return renderMap(<QuoteDetailMapWithData locationData={quote} />);
}
