import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';

export const NewShipmentSubRoute = {
  ACCOUNT_SELECTION: 'account-selection',
  CONFIRM_ORDER: 'confirm-order',
};

export default function useNewShipmentNiceUrl({
  baseUrl,
  account,
  isConfirming,
  step,
}) {
  const { replace, location } = useHistory();
  const goTo = useCallback(
    newPath => {
      const { pathname, search } = location || {};
      if (newPath !== pathname) {
        replace(`${newPath}${search}`);
      }
    },
    [location, replace]
  );

  const accountNotSelected = !account;

  useEffect(() => {
    if (accountNotSelected) {
      goTo(`${baseUrl}/account-selection`);
    } else if (isConfirming) {
      goTo(`${baseUrl}/confirm-order`);
    } else if (step) {
      goTo(`${baseUrl}/${step}`);
    }
  }, [accountNotSelected, baseUrl, goTo, isConfirming, step]);
}
