import { TwoLinesSeparator } from '../../../components/layout/layoutElements';
import SectionHeader from './SectionHeader';

export default function ContactInformation({ contact }) {
  if (!contact) return null;

  return (
    <>
      <SectionHeader title="admin.userManagement.forms.contactInformation" />
      {contact.name}
      <TwoLinesSeparator />
      {contact.phone}
      <TwoLinesSeparator />
      {contact.email}
    </>
  );
}
