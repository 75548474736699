import { Col, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { TwoLinesSeparator } from '../../../components/layout/layoutElements';
import DateTimeDisplay from './DateTimeDisplay';
import { DateFormatMedium } from '../../../utils/dateFormatting';
import { cssVariables } from '../../../styles/cssVariables';
import { ToBeDefined } from './components/NoValue';

export default function ShipmentDetails({
  title,
  subtitle,
  dateTime,
  children,
}) {
  return (
    <Col span={6}>
      <div className="ShipmentDetails">
        <TwoLinesSeparator
          style={{
            marginTop: cssVariables.spaceLg,
            marginBottom: cssVariables.spaceSm,
          }}
        />
        <Typography.Title level={3} className="text-uppercase shipment-title">
          <FormattedMessage id={title} />
        </Typography.Title>
        <TwoLinesSeparator />
        <Typography.Title level={3} className="text-uppercase shipment-status">
          <FormattedMessage id={subtitle} />
          <div className="text-highlight">
            {dateTime ? (
              <DateTimeDisplay
                dateTime={dateTime}
                formatComponent={DateFormatMedium}
              />
            ) : (
              <ToBeDefined />
            )}
          </div>
        </Typography.Title>
        {children}
      </div>
    </Col>
  );
}
