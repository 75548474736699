import { FormattedUnit } from '../../../../components/domainSpecific/measuringUnitsElements';

const WaypointLine = ({ distance, inputUnit }) => (
  <div className="waypoint-line-wrapper">
    <i className="far fa-circle waypoint-icon waypoint-icon-top" />
    <i className="fas fa-dot-circle waypoint-icon waypoint-icon-bottom" />
    <div className="waypoint-line">
      <div className="waypoint-badge">
        <FormattedUnit
          unitType="distance"
          value={distance}
          inputUnit={inputUnit}
        />
      </div>
    </div>
  </div>
);

export default WaypointLine;
