import { Typography } from 'antd';
import { map } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import SectionHeader from '../SectionHeader';
import Badge from './Badge';
import DateTimeDisplay from '../DateTimeDisplay';
import {
  DateFormatNumeric,
  DateFormatShortWeekday,
} from '../../../../utils/dateFormatting';
import { TwoLinesSeparator } from '../../../../components/layout/layoutElements';
import AddressDisplay from './AddressDisplay';
import WaypointLine from './WaypointLine';
import { NoValue, ToBeDefined } from './NoValue';

export default function FlightInformationSection({
  sectionTitle,
  dateTime,
  highlightedText,
  address,
  badgeLabel,
  distance,
  flightRows,
}) {
  return (
    <div className="flight-info-section">
      <SectionHeader title={sectionTitle} />
      <div className="badge-date-section">
        <Badge label={badgeLabel} />
        <Typography.Title level={4}>
          {dateTime ? (
            <DateTimeDisplay
              dateTime={dateTime}
              timeClass="text-highlight"
              formatComponent={DateFormatShortWeekday}
            />
          ) : (
            <ToBeDefined />
          )}
        </Typography.Title>
      </div>
      <TwoLinesSeparator />
      <p className="text-highlight flight-section-header">{highlightedText}</p>
      {address && (
        <>
          <TwoLinesSeparator />
          <AddressDisplay address={address} />
        </>
      )}
      {map(flightRows, ({ label, value }) => (
        <div key={label}>
          <TwoLinesSeparator />
          <div>
            <FormattedMessage id={label} />:{' '}
            {value ? (
              <DateTimeDisplay
                dateTime={value}
                formatComponent={DateFormatNumeric}
              />
            ) : (
              <NoValue />
            )}
          </div>
        </div>
      ))}
      <TwoLinesSeparator />
      {distance && <WaypointLine distance={distance} />}
    </div>
  );
}
