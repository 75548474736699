import { Col, Form, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import { findLastIndex, get, isEmpty, last, noop } from 'lodash-es';
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { useMountAndUpdateEffect } from '../../../common/utils/hookUtils';
import IconButton from '../../../components/buttons/IconButton';
import { FormItemOverrideContext } from '../../../components/forms/FormItem';
import FormList from '../../../components/forms/FormList';
import SilentFormValidation from '../../../components/forms/SilentFormValidation';
import { FormItemFieldRegistration } from '../../../components/forms/basicFormElements';
import { FormItemInlineCheckbox } from '../../../components/forms/checkable';
import { DynamicFormDependenciesProvider } from '../../../components/forms/dynamic/dynamicFormDependencies';
import { isFormSectionNonEmpty } from '../../../components/forms/formHelpers';
import {
  FormInterceptorContext,
  FormMode,
  useFormContext,
} from '../../../components/forms/forms';
import { withIntermediateSubformState } from '../../../components/forms/subformDecorators';
import { SlidingContent } from '../../../components/nav/SlidingContent';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { useScrollToThis } from '../../../utils/scrollUtils';
import { SavedShipmentContext } from '../newShipment/SavedShipmentProvider';
import {
  getPackageCount,
  getValidPackages,
  isPackageValid,
} from '../shipmentCommon';
import PackageListPackageEditingMode from './PackageListPackageEditingMode';
import PackageListPackagePreviewMode from './PackageListPackagePreviewMode';
import { PackageCard } from './packageListCommon';
import { PackageConsistentWeightModeDialog } from './packageListDialogs';
import {
  usePackageList,
  usePackagesIntermediateSubformStates,
} from './usePackageList';

const PackageFormInner = withIntermediateSubformState({
  renderEditing: props => <PackageListPackageEditingMode {...props} />,
  renderPreview: props => <PackageListPackagePreviewMode {...props} />,
});

function PackageForm({
  index,
  remove: removeIndex,
  name,
  fieldKey,
  hasError,
  ...rest
}) {
  const remove = () => removeIndex(index);
  const ref = useScrollToThis({ active: hasError });

  const { values } = useFormContext();
  const isFormLoaded = !!values.packages;
  const outerValue = get(values, ['packages', index]);
  const requiredFields = ['packageSource', 'packagingType']; // Actual Weight and LWH can be null sometimes
  const isRequiredFieldsFill = isPackageValid(outerValue, requiredFields);
  const formMode =
    isFormSectionNonEmpty(outerValue) ||
    !isFormLoaded ||
    isPackageValid(outerValue, requiredFields)
      ? FormMode.EDIT
      : FormMode.ADD;

  return (
    <>
      <Form.Item noStyle name={name} fieldKey={fieldKey}>
        <PackageFormInner
          index={index}
          remove={remove}
          formMode={formMode}
          {...rest}
          editing={rest.editing || !isRequiredFieldsFill}
        />
      </Form.Item>
      <div
        ref={ref}
        className={classNames('NewShipmentPackage-ErrorMessage', {
          'has-content': hasError,
        })}
      >
        {hasError && <FormattedMessage id="error.package" />}
      </div>
    </>
  );
}

function TooltipWrapper({ titleId, children }) {
  return titleId ? (
    <Tooltip
      title={<FormattedMessage id={titleId} />}
      overlayClassName="ant-tooltip-custom-lines"
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
}

export function AddOrCopyPackageButtons({ add, disabled, disabledReasonId }) {
  const { values } = useFormContext();
  const packages = getValidPackages(values?.packages);
  const lastPkg = last(packages);
  const addNew = useCallback(() => add({}), [add]);
  const copyLast = useCallback(() => add(lastPkg || {}), [add, lastPkg]);

  return (
    <div className="AddOrCopyPackageButtons">
      <TooltipWrapper titleId={disabledReasonId}>
        <IconButton
          icon="plus-square"
          onClick={addNew}
          titleId={disabled ? '' : 'buttons.add'}
          disabled={disabled}
          data-subject="packages"
          data-action="add"
        />
      </TooltipWrapper>
      {lastPkg && (
        <IconButton
          icon="copy"
          onClick={copyLast}
          titleId="buttons.copy"
          data-subject="packages"
          data-action="copy"
        />
      )}
      <span className="text">
        <FormattedMessage
          id={
            lastPkg
              ? 'book.newShipment.packages.addOrCopyPackage'
              : 'book.newShipment.packages.addPackage'
          }
        />
      </span>
    </div>
  );
}

export function PackageFormsBigScreen({ packageForms }) {
  return packageForms;
}

function RenderChildren({ children }) {
  return children;
}
export function PackageFormsSmallScreen({ packageForms, packageErrors }) {
  const data = packageForms.map((packageForm, i) => ({
    name: `${i + 1}`,
    props: { children: packageForm },
  }));
  const [selected, setSelected] = useState(data[0].name);
  const selectedIndex = parseInt(selected, 10) - 1;

  useMountAndUpdateEffect(
    {
      onUpdate: ([prevLength]) => {
        // Select the last after adding (it should be edited)
        if (packageForms.length > prevLength) {
          setSelected(last(data).name);
        }
        // Select the last after removal
        if (selectedIndex >= data.length) {
          setSelected(last(data).name);
        }
      },
    },
    [packageForms.length, selectedIndex]
  );

  const lastErrIndex = findLastIndex(packageErrors, val => !!val);
  useMountAndUpdateEffect(
    {
      onUpdate: ([prevLastErrIndex]) => {
        // Select the last erroneous after validation
        if (lastErrIndex > -1) {
          setSelected(data[lastErrIndex].name);
        }
      },
    },
    [lastErrIndex]
  );

  const goToPrev = () => {
    if (selectedIndex > 0) {
      setSelected(data[selectedIndex - 1].name);
    }
  };
  const goToNext = () => {
    if (selectedIndex < data.length - 1) {
      setSelected(data[selectedIndex + 1].name);
    }
  };

  return (
    <div className="NewShipmentPackagesListSlider">
      <Row
        className="NewShipmentPackagesListSlider-Header"
        align="middle"
        justify="space-between"
      >
        <Col className="NewShipmentPackagesListSlider-Label">
          <FormattedMessage id="book.newShipment.packagesSliderHeader" />
        </Col>
        <Col>
          <Row align="middle" gutter={pxToNumber(cssVariables.spaceNorm)}>
            <Col>
              <IconButton icon="caret-square-left" onClick={goToPrev} />
            </Col>
            {data.map(({ name }) => (
              <Col key={name}>
                <div
                  className={classNames(
                    'NewShipmentPackagesListSlider-Button',
                    { active: name === selected }
                  )}
                  onClick={() => setSelected(name)}
                >
                  {name}
                </div>
              </Col>
            ))}
            <Col>
              <IconButton icon="caret-square-right" onClick={goToNext} />
            </Col>
          </Row>
        </Col>
      </Row>
      <SlidingContent
        ContentComponent={RenderChildren}
        data={data}
        listenToSelected
        selected={selected}
        onSelect={setSelected}
        autoHeight
      />
    </div>
  );
}

function PackageListPackagesInner({
  onEditedPackageChange,
  activeTemplateCounter,
  fields,
  add,
  onAddPackage,
  remove,
  packageErrors,
  pkgControlsRef,
}) {
  const { disabled, viewMode } = useContext(FormItemOverrideContext);
  const { onBlur: onBlurGlobal } = useContext(FormInterceptorContext);

  const { formInstance, forceUpdate, values } = useFormContext();
  const { enabled: savedShipmentEnabled } = useContext(SavedShipmentContext);

  const {
    getIntermediateSubformState,
    removeFromState,
    startEditing,
    isAnyEdited,
    stopEditingAll,
    removeAllFromState,
  } = usePackagesIntermediateSubformStates({ onEditedPackageChange });

  const tbdValue = values.tbd;
  const onTbdChange = useCallback(
    val => {
      formInstance.setFieldsValue({ tbd: val });
      if (val) {
        removeAllFromState();
        formInstance.setFieldsValue({ packages: [] });
      }
      forceUpdate();
    },
    [forceUpdate, formInstance, removeAllFromState]
  );
  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm || media.md;

  const noPackageExists = isEmpty(fields);

  // Disable TBD if any package is saved
  const numSavedPackages = getPackageCount(values.packages);
  useEffect(() => {
    if (savedShipmentEnabled && tbdValue && numSavedPackages > 0) {
      onTbdChange(false);
    }
  }, [numSavedPackages, onTbdChange, savedShipmentEnabled, tbdValue]);

  useMountAndUpdateEffect(
    {
      onUpdate: () => {
        stopEditingAll();
        requestAnimationFrame(() => {
          // Open the first package when template is opened
          if (!noPackageExists) {
            startEditing(0);
          }
        });
      },
    },
    [activeTemplateCounter]
  );

  const addAndStartEdit = useCallback(
    val => {
      add(val);
      const index = fields.length;
      startEditing(index);
      onAddPackage({ index, value: val });
    },
    [add, fields.length, onAddPackage, startEditing]
  );
  const removeIncludingState = index => {
    removeFromState(index);
    remove(index);
    if (getIntermediateSubformState(index).editing) {
      onEditedPackageChange(null, null);
    }
    // Removal should trigger the same response as blur
    onBlurGlobal();
  };

  useImperativeHandle(pkgControlsRef, () => ({ addAndStartEdit }));

  if (noPackageExists) {
    const showInitialAddButton = !disabled && !viewMode;
    if (!showInitialAddButton) {
      return null;
    }
    return (
      <PackageCard>
        <div className="NewShipmentCardBody">
          <Row
            align="middle"
            justify={savedShipmentEnabled ? 'space-between' : 'start'}
          >
            <Col>
              <AddOrCopyPackageButtons
                add={addAndStartEdit}
                disabled={tbdValue}
                disabledReasonId={
                  tbdValue && 'book.newShipment.package.disabled.tbd'
                }
              />
            </Col>
            {savedShipmentEnabled && (
              <Col>
                <div className="NewShipmentPackage-TBDCheckbox inline">
                  <FormItemInlineCheckbox
                    labelId="labels.tbd.saveShipment"
                    formItemComponentProps={{
                      checked: tbdValue,
                      onChange: e => onTbdChange(e.target.checked),
                    }}
                    data-subject="packages"
                    data-role="tbd-switch"
                  />
                </div>
              </Col>
            )}
          </Row>
        </div>
      </PackageCard>
    );
  }

  const showAddButton = !disabled && !viewMode && !isAnyEdited;
  const packageForms = (fields || []).map((field, index) => (
    <PackageForm
      {...field}
      index={index}
      remove={removeIncludingState}
      {...getIntermediateSubformState(index)}
      hasError={packageErrors[index]}
      showTbd={
        savedShipmentEnabled &&
        index === 0 &&
        get(values, 'packages[0].fromTemplate')
      }
      tbdValue={tbdValue}
      onTbdChange={onTbdChange}
    />
  ));

  return (
    <>
      {isSm ? (
        <PackageFormsSmallScreen
          packageForms={packageForms}
          packageErrors={packageErrors}
        />
      ) : (
        <PackageFormsBigScreen
          packageForms={packageForms}
          packageErrors={packageErrors}
        />
      )}
      {showAddButton && (
        <AddOrCopyPackageButtons
          add={addAndStartEdit}
          disabled={tbdValue}
          disabledReasonId={tbdValue && 'book.newShipment.package.disabled.tbd'}
        />
      )}
    </>
  );
}

export default function PackageListPackages({
  onEditedPackageChange: onEditedPackageChangeOuter,
  activeTemplateCounter,
  pkgControlsRef,
}) {
  const {
    onAddPackage,
    isOpen,
    close,
    onEditedPackageChange,
    validateAllPackages,
    parentDependencies,
    validationRef,
    packageErrors,
  } = usePackageList(onEditedPackageChangeOuter);

  return (
    <>
      <FormItemFieldRegistration
        name="allPackagesValidator"
        rules={[{ validator: () => validateAllPackages(true) }]}
      />
      <FormItemFieldRegistration name="tbd" />
      <SilentFormValidation ref={validationRef}>
        {({ values, index }) => (
          <DynamicFormDependenciesProvider
            values={{ ...parentDependencies, packages: values }}
          >
            <PackageListPackageEditingMode
              index={index}
              pieceListProps={{
                editing: true,
                setEditing: noop,
                tmpValues: values,
              }}
            />
          </DynamicFormDependenciesProvider>
        )}
      </SilentFormValidation>
      <FormList name="packages">
        {(fields, { add, remove }) => (
          <PackageListPackagesInner
            onEditedPackageChange={onEditedPackageChange}
            activeTemplateCounter={activeTemplateCounter}
            fields={fields}
            add={add}
            onAddPackage={onAddPackage}
            remove={remove}
            packageErrors={packageErrors}
            pkgControlsRef={pkgControlsRef}
          />
        )}
      </FormList>
      <PackageConsistentWeightModeDialog isOpen={isOpen} close={close} />
    </>
  );
}
