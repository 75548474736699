import { message } from 'antd';
import moment from 'moment';

export const showQuoteMessage = (formatMessage, onClose) => {
  const key = Math.random();
  message.warning({
    content: (
      <div className="quotes-notification-content">
        {formatMessage({ id: 'book.newQuote.warning-restriction' })}
        <br />
        {formatMessage({ id: 'book.newQuote.warning-contact' })}
        <a
          href="https://quick.aero/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          <u>
            {formatMessage({
              id: 'book.newQuote.warning-customer-service',
            })}
          </u>
        </a>
      </div>
    ),
    className: 'quotes-notification',
    duration: 10,
    onClose,
    key,
  });
  return key;
};

export const showQuoteExpirationMessage = (
  formatMessage,
  expiryDateTime,
  onClose
) => {
  const key = Math.random();
  message.warning({
    content: (
      <div className="quotes-notification-content">
        {formatMessage({ id: 'book.quote.savedQuoteMessage' })}
        <br />
        {formatMessage(
          {
            id: 'book.quote.expireQuote',
          },
          {
            expireDate: moment(expiryDateTime)
              .format('DDMMMYYYY')
              .toUpperCase(),
          }
        )}
      </div>
    ),
    className: 'quotes-notification',
    duration: 10,
    onClose,
    key,
  });
  return key;
};

export const showRetrieveQuoteMessage = (formatMessage, onClose) => {
  const key = Math.random();
  message.warning({
    content: (
      <div className="quotes-notification-content">
        {formatMessage({ id: 'quote.retrieveQuote.title' })}
        <br />
        {formatMessage({
          id: 'quote.retrieveQuote.message',
        })}
      </div>
    ),
    className: 'quotes-notification',
    duration: 10,
    onClose,
    key,
  });
  return key;
};
