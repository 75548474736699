import { useApolloClient, useMutation } from '@apollo/client';
import { assign, isFunction } from 'lodash-es';
import { useCallback, useReducer } from 'react';

import { formToGraphqlShipmentInput } from '../../app/data/newShipmentConversions';
import { PREPARE_SHIPMENT_QUERY } from '../../app/graphql/jobFormQueries';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';
import ModalOverlay from '../../components/dialogs/ModalOverlay';
import ProcessingModal from '../../components/dialogs/ProcessingModal';
import ConfirmOrderPage from '../../containers/ConfirmOrderPage';

const START_SENDING = 'START_SENDING';
const DRAFT_READY = 'DRAFT_READY';
const VALIDATION_ERROR = 'VALIDATION_ERROR';
const CLEAR = 'CLEAR';

const INITIAL_SHIPMENT_SUBMIT_STATE = {
  startedSending: false,
  confirmData: undefined,
  successDialogOpen: false,
};

function shipmentSubmitReducer(state, action) {
  switch (action.type) {
    case START_SENDING:
      return { ...state, startedSending: true };
    case DRAFT_READY:
      return { ...state, confirmData: action.data };
    case CLEAR:
      return { ...INITIAL_SHIPMENT_SUBMIT_STATE };
    case VALIDATION_ERROR:
      return { ...state, startedSending: false };
    default:
      return state;
  }
}

export function useShipmentSubmitState({
  mutation,
  mutationOptions,
  prepareShipmentQuery = PREPARE_SHIPMENT_QUERY,
  jobNumber = null,
  quoteReference = null,
}) {
  const { errorMessage } = useFlashMessageContext();
  const [state, dispatch] = useReducer(shipmentSubmitReducer, {
    ...INITIAL_SHIPMENT_SUBMIT_STATE,
  });

  const [mutate] = useMutation(mutation, mutationOptions);
  const client = useApolloClient();

  async function submit(shipmentForm, { validateFields }) {
    try {
      dispatch({ type: START_SENDING });
      if (isFunction(validateFields)) {
        const validationResult = await validateFields();
        if (!validationResult) {
          dispatch({ type: VALIDATION_ERROR });
          return;
        }
      }
      // In case shipment form is expected to change during validation, a function is allowed
      const finalShipmentForm = isFunction(shipmentForm)
        ? shipmentForm()
        : shipmentForm;
      const { data } = await client.query({
        query: prepareShipmentQuery,
        variables: {
          input: formToGraphqlShipmentInput(finalShipmentForm),
          jobNumber,
          quoteReference,
        },
      });
      const confirmData = {
        ...finalShipmentForm,
        quoteTotal: data.prepareShipment?.quoteTotal,
        quoteReference: data.prepareShipment?.quoteReference,
        originLocation: data.prepareShipment?.originLocation,
        destinationLocation: data.prepareShipment?.destinationLocation,
      };
      dispatch({ type: DRAFT_READY, data: confirmData });
    } catch (err) {
      dispatch({ type: VALIDATION_ERROR });
      errorMessage(err);
    }
  }
  const onCancel = useCallback(() => {
    dispatch({ type: CLEAR });
  }, []);

  async function onConfirm({ shipmentForm }) {
    try {
      const variables = assign(
        { input: formToGraphqlShipmentInput(shipmentForm) },
        jobNumber && { jobNumber },
        quoteReference && { quoteReference }
      );
      const { data } = await mutate({ variables });
      return extractGraphqlEntity(data);
    } catch (err) {
      errorMessage(err);
    }
    return null;
  }

  return {
    submit,
    isConfirming: !!state.confirmData,
    dialogProps: { ...state, onConfirm, onCancel },
  };
}

function OptimizingRouteDialog({
  preparingLabelId = 'book.newShipment.submit.preparingShipment',
}) {
  return <ProcessingModal textId={preparingLabelId} />;
}

function ConfirmOrderDialog({ data, onConfirm, onCancel, ...rest }) {
  return (
    <ModalOverlay>
      <ConfirmOrderPage
        data={data}
        onConfirm={onConfirm}
        onCancel={onCancel}
        {...rest}
      />
    </ModalOverlay>
  );
}

export function BaseShipmentSubmitStateDialog({
  startedSending,
  confirmData,
  onConfirm,
  onCancel,
  confirmOrderPageProps,
  optimizingRouteDialogProps,
}) {
  if (confirmData) {
    return (
      <ConfirmOrderDialog
        data={confirmData}
        onConfirm={onConfirm}
        onCancel={onCancel}
        {...confirmOrderPageProps}
      />
    );
  }
  if (startedSending) {
    return <OptimizingRouteDialog {...optimizingRouteDialogProps} />;
  }
  return null;
}
