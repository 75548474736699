import { gql } from '@apollo/client';

import {
  codeValueFragment,
  gpsLocationFragment,
  listLocationFragment,
  makeLocationFragment,
} from './commonFragments';

export const CREATE_QUOTE_MUTATION = gql`
  mutation CreateQuoteMutation($input: QuoteInput!) {
    createQuote(input: $input) {
      referenceNo
    }
  }
`;

export const DELETE_QUOTE_MUTATION = gql`
  mutation DeleteQuoteMutation($referenceNo: String!) {
    deleteQuote(referenceNo: $referenceNo)
  }
`;

const locationFragment = makeLocationFragment({
  fullState: true,
  otherFields: 'airportDistance',
});

export const QUOTE_DETAIL_QUERY = gql`
  query CustomerQuoteDetailQuery($referenceNo: String!) {
    getCustomerQuote(referenceNo: $referenceNo){   
      referenceNo
      metadata {
        qolAccount {
          number
          name
        }
      }
      commodity
      serviceType ${codeValueFragment}
      origin ${locationFragment}      
      destination ${locationFragment}
      deliveryDateTime {
        scheduled
      }
      pickupDateTime {
        scheduled
      }
      expiryDateTime
      dangerousGoods
      unNumber {
        code
        text
      }
      exceptedQty
      packages {
        actualWeight
        length
        width
        height
      }
      pieces
      weight
      quoteTotal
      unitSystem
      amounts {
        value
        desc
      }
      flights {
        airline
        flightNumber
        originAirportCode
        destinationAirportCode
        lockoutDateTime
        departure {
          estimated
        }
        arrival {
          estimated
        }
        originAirportLocation ${gpsLocationFragment}
        destinationAirportLocation ${gpsLocationFragment}
      }
    }
  }
`;

export const QUOTES_QUERY = gql`
  query CustomerQuotesQuery($pageIndex: Int, $pageSize: Int, $filter: QuoteFilter, $sort: Sort) {
    customerQuotes(pageIndex: $pageIndex, pageSize: $pageSize, filter: $filter, sort: $sort) {
      pageIndex
      pageSize
      total
      data {
        metadata {
          qolAccount {
            number
            name
          }
        }
        quoteNumber
        referenceNo
        badge
        origin ${listLocationFragment}
        destination ${listLocationFragment}
        quoteStatus
        pickupDateTime {
          scheduled
        }
        deliveryDateTime {
          scheduled
        }
        expiryDateTime
        shippersReferences
      }
    }
  }
`;
