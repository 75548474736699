import { Col, Row, Typography } from 'antd';
import { map } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { cssVariables } from '../../../styles/cssVariables';
import { DateTimeFormatNumericNoYear } from '../../../utils/dateFormatting';
import { TwoLinesSeparator } from '../../../components/layout/layoutElements';
import logoImg from '../../../resources/img/airlines/delta.png';
import { NoValue } from './components/NoValue';

export function QuoteFlightItinerary({ flights }) {
  return (
    <div>
      <TwoLinesSeparator
        style={{
          marginTop: cssVariables.spaceLg,
          marginBottom: cssVariables.spaceSm,
        }}
      />
      <Row>
        <Typography.Title level={3} className="text-uppercase flight-title">
          <FormattedMessage id="book.newQuote.flightItinerary" />
        </Typography.Title>
      </Row>
      {map(flights, flight => (
        <div key={flight} className="itinerary-card">
          <Row justify="space-between">
            <Col span={24}>
              <TwoLinesSeparator />
            </Col>
            <Col span={11}>
              <Typography.Text className="itinerary-label">
                <FormattedMessage id="book.quote.flightInformation.status" />
                <span className="itinerary-status">
                  {flight.status || (
                    <FormattedMessage id="book.quote.flightInformation.status.onTime" />
                  )}
                </span>
              </Typography.Text>
              <Row gutter={[8, 8]}>
                <div className="airline-logo">
                  <img src={logoImg} alt="" />
                </div>
                {[
                  { label: 'airline', value: flight.airline },
                  { label: 'flight', value: flight.flightNumber },
                  { label: 'origin', value: flight.originAirportCode },
                  {
                    label: 'destination',
                    value: flight.destinationAirportCode,
                  },
                ].map(({ label, value }) => (
                  <Col span={4} key={label}>
                    <Typography.Text className="itinerary-label">
                      <FormattedMessage
                        id={`book.quote.flightInformation.${label}`}
                      />
                    </Typography.Text>
                    <div className="itinerary-value">{value}</div>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col span={2} className="flex-center">
              <hr className="vertical-separator" />
            </Col>

            {/* Schedule Details */}
            <Col span={11}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <Row style={{ height: '24px' }} />
                  <Row>
                    <Typography.Text className="itinerary-label itinerary-label--bold">
                      <FormattedMessage id="book.quote.flightInformation.scheduled" />
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text className="itinerary-label itinerary-label--bold">
                      <FormattedMessage id="book.quote.flightInformation.estimated" />
                    </Typography.Text>
                  </Row>
                </Col>
                {[
                  {
                    label: 'depart',
                    start:
                      flight.departure.scheduled || flight.departure.estimated,
                    end: flight.departure.estimated,
                  },
                  {
                    label: 'arrive',
                    start: flight.arrival.scheduled || flight.arrival.estimated,
                    end: flight.arrival.estimated,
                  },
                ].map(({ label, start, end }) => (
                  <Col span={8} key={label}>
                    <Typography.Text className="itinerary-label">
                      <FormattedMessage
                        id={`book.quote.flightInformation.${label}`}
                      />
                    </Typography.Text>

                    <div className="itinerary-value">
                      {start ? (
                        <DateTimeFormatNumericNoYear value={moment(start)} />
                      ) : (
                        <NoValue />
                      )}
                    </div>

                    <div className="itinerary-value">
                      {end ? (
                        <DateTimeFormatNumericNoYear value={moment(end)} />
                      ) : (
                        <NoValue />
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      ))}
      <Row>
        <Col span={24}>
          <TwoLinesSeparator />
        </Col>
      </Row>
    </div>
  );
}
