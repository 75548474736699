/* eslint-disable no-debugger */
import { compact, range } from 'lodash-es';
import { useIntl } from 'react-intl';

import { useFlashMessageContext } from '../../../components/dialogs/FlashMessageProvider';
import { isLastStep } from '../../../components/nav/StepsWithContent';

export default function useSubmitNewShipmentStep({
  forms,
  stepsProps,
  currentIndex,
  submit,
  extractValues,
  isQuoteEnabled = false,
  goToNext,
  steps,
}) {
  const intl = useIntl();
  const { errorMessage } = useFlashMessageContext();

  return () =>
    forms[currentIndex].formRef.current.customSubmit(async () => {
      if (isLastStep(stepsProps)) {
        // Validate all the previous steps (they may have been altered after their validation)
        const validationPromises = range(0, currentIndex).map(async i => {
          try {
            await forms[i].form.validateFields();
          } catch (e) {
            return steps[i].titleId;
          }
          return undefined;
        });
        const invalidSteps = compact(await Promise.all(validationPromises));
        if (invalidSteps.length > 0) {
          errorMessage({
            contentId: 'error.newShipment.invalidSteps',
            contentValues: {
              steps: invalidSteps
                .map(titleId => intl.formatMessage({ id: titleId }))
                .join(', '),
            },
          });
          return;
        }

        submit(extractValues(), { isQuoteEnabled });
      } else {
        goToNext();
      }
    });
}
