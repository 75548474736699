export function latLng(lat, lng) {
  return { lat, lng };
}

export function point(x, y) {
  return { x, y };
}

export function mapSize(width, height) {
  return window.google && new window.google.maps.Size(width, height);
}

const toRadians = degrees => (degrees * Math.PI) / 180;
const toDegrees = radians => (radians * 180) / Math.PI;

const getBearing = (origin, destination) => {
  const { lng: lon1, lat: lat1 } = origin;
  const { lng: lon2, lat: lat2 } = destination;

  const phi1 = toRadians(lat1);
  const phi2 = toRadians(lat2);
  const deltaLambda = toRadians(lon2 - lon1);

  const y = Math.sin(deltaLambda) * Math.cos(phi2);
  const x =
    Math.cos(phi1) * Math.sin(phi2) -
    Math.sin(phi1) * Math.cos(phi2) * Math.cos(deltaLambda);

  let theta = Math.atan2(y, x);
  theta = toDegrees(theta);
  return (theta + 360) % 360; // Normalize to 0-360 degrees
};

export const getDirection = (origin, destination) => {
  if (!origin || !destination) return 0;

  const bearing = getBearing(origin, destination);

  if (bearing > 0 && bearing < 180) {
    return 1;
  }

  if (bearing > 180) {
    return -1;
  }

  return 0;
};

export function createMapBounds(coordinates) {
  const bounds = new window.google.maps.LatLngBounds();
  coordinates.forEach(coords => {
    if (!bounds.contains(coords)) {
      bounds.extend(coords);
    }
  });
  return bounds;
}
