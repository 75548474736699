import { toServerDateTimeOptional } from '../../common/utils/dateUtils';

function formLocationToGraphqlInput(location) {
  return {
    companyName: location?.companyName,
    address: {
      addressLine1: location?.address?.addressLines?.[0],
      addressLine2: location?.address?.addressLines?.[1],
      addressLine3: location?.address?.addressLines?.[2],
      city: location?.address?.city,
      stateProvince: location?.address?.stateProvince,
      postalCode: location?.address?.postalCode,
      country: location?.address?.country,
    },
    contact: location?.contact,
  };
}

export function formToGraphqlQuoteInput(form) {
  const {
    account,
    serviceType,
    origin,
    destination,
    packages,
    pickupDateTime,
    commodity,
    dangerousGoods,
    exceptedQuantity,
    unNumber = [],
    unitSystem,
  } = form || {};
  return {
    account: account?.number,
    serviceType: serviceType?.code,
    pickupDateTime: toServerDateTimeOptional(pickupDateTime),
    shipper: formLocationToGraphqlInput(origin),
    consignee: formLocationToGraphqlInput(destination),
    unitSystem,
    packageInfo: packages?.map(
      ({ actualWeight, length, height, width }, index) => ({
        commodity,
        dangerousGoods,
        unNumber: unNumber?.map(number => ({ code: number?.code })),
        exceptedQty: exceptedQuantity,
        packageOptions: {
          pieces: 1,
          dimensionalPackage: {
            case: index,
            actualWeight,
            length,
            height,
            width,
          },
        },
      })
    ),
  };
}
