import { compact, map, pick } from 'lodash-es';

export function normalizeAccount(account) {
  return {
    name: account.name,
    number: account.number,
    partner: !!account.isPartner,
    newOrder: {
      enabled: !!account.isOrderEnabled,
      mode: account.shipmentMode,
      features: {
        dangerousGoods: !!account.isDangerousGoods,
      },
    },
    newQuote: {
      enabled: !!account.isQuoteEnabled,
    },
  };
}

export function accountGraphqlToForm(account) {
  return {
    ...pick(account, [
      'name',
      'profile',
      'active',
      'isOrderEnabled',
      'shareableUrl',
      'isQuoteEnabled',
      'forceDeadline',
      'fullAddressBookAccess',
      'addressBookSharedBy',
      'addressBookSharedWith',
      'serviceTypesSettings',
      'commodityTypesSettings',
      'gpsDevicesSettings',
      'tempDevicesSettings',
      'packageTempSettings',
      'packageTypesSettings',
      'documentsSettings',
    ]),
    address: {
      city: account.address?.city?.text,
      stateProvince: account.address?.stateProvince?.code,
      country: account.address?.country?.code,
      postalCode: account.address?.postalCode,
      street: compact([
        account.address?.addressLine1,
        account.address?.addressLine2,
        account.address?.addressLine3,
      ]).join(', '),
    },
  };
}

export function accountFormToGraphql(values) {
  return {
    ...pick(values, [
      'profile',
      'active',
      'isOrderEnabled',
      'shareableUrl',
      'isQuoteEnabled',
      'isDangerousGoods',
      'forceDeadline',
    ]),
    addressBookSharedWith: map(values.addressBookSharedWith, val => val.number),
    serviceTypesSettings: pickArrayEditSettings(values?.serviceTypesSettings),
    commodityTypesSettings: pickArrayEditSettings(
      values?.commodityTypesSettings
    ),
    gpsDevicesSettings: pickArrayEditSettings(values?.gpsDevicesSettings),
    tempDevicesSettings: pickArrayEditSettings(values?.tempDevicesSettings),
    documentsSettings: pickArrayEditSettings(values.documentsSettings),
    packageTypesSettings: pickArrayEditSettings(values.packageTypesSettings),
    packageTempSettings: pickArrayEditSettings(values.packageTempSettings),
  };
}

function pickArrayEditSettings(values) {
  return pick(values, ['default', 'add', 'remove']);
}
