import { Col, Modal, Row } from 'antd';
import { compact, sum } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { CustomLoaderButton } from '../components/buttons/buttons';
import { ConfigurableCard } from '../components/layout/cardElements';
import { QuotePackagesPreviewMode } from '../forms/shipments/packageList/QuotePackagesPreviewMode';
import { WeightFormat } from '../components/domainSpecific/measuringUnitsElements';
import { CurrencyFormat } from '../components/domainSpecific/currencyElements';
import { DateFormatShortWithDashes } from '../utils/dateFormatting';

const notIncludedItems = [
  'weightDiscrepancies',
  'attemptedDeliveries',
  'attemptedCollections',
  'waitingTime',
  'vehicleInUse',
  'reIcing',
  'dutiesAndTaxes',
  'packagingCosts',
];

export default function ViewQuoteBreakdownDialog({ isOpen, close, quote }) {
  const addresses = [
    {
      labelId: 'book.quote.quoteBreakdown.pickupAddress',
      address: quote?.origin?.address,
    },
    {
      labelId: 'book.quote.quoteBreakdown.deliveryAddress',
      address: quote?.destination?.address,
    },
  ];

  const quoteInfo1 = [
    {
      labelId: 'quoteDate',
      value: quote?.creationDate && (
        <DateFormatShortWithDashes value={moment(quote?.creationDate)} />
      ),
    },
    {
      labelId: 'clientName',
      value: quote?.origin?.companyName,
    },
    {
      labelId: 'ACCT',
      value: quote?.metadata?.qolAccount?.number,
    },
    {
      labelId: 'serviceType',
      value: quote?.serviceType?.text,
    },
    {
      labelId: 'quotedAmount',
      value: (
        <CurrencyFormat value={quote?.quoteTotal} minimumFractionDigits={2} />
      ),
    },
  ];

  const quoteInfo2 = [
    {
      labelId: 'validToDate',
      value: quote?.expiryDateTime && (
        <DateFormatShortWithDashes value={moment(quote?.expiryDateTime)} />
      ),
    },
    {
      labelId: 'dateValidFor',
    },
    {
      labelId: 'jobQuote',
      value: quote?.referenceNo,
    },
  ];

  return (
    <Modal
      className="ViewQuoteBreakdownDialog"
      open={isOpen}
      footer={null}
      closable={false}
      width={600}
      centered
    >
      <div className="ViewQuoteBreakdownDialog-ControlButtons">
        <h1>
          <FormattedMessage id="book.quote.quoteBreakdown.title" />
        </h1>
        <CustomLoaderButton
          type="primary"
          onClick={async () => {
            close();
          }}
          data-action="close"
        >
          <FAIcon icon="times-circle" />
          <span>
            <FormattedMessage id="buttons.close" />
          </span>
        </CustomLoaderButton>
      </div>
      <div className="ViewQuoteBreakdownDialog-ScrollablePanel">
        <Row gutter={[20, 10]} className="ViewQuoteBreakdownDialog-QuoteInfo">
          <Col span={12}>
            {quoteInfo1?.map(({ labelId, value }) => (
              <div key={labelId}>
                <span>
                  <FormattedMessage
                    id={`book.quote.quoteBreakdown.${labelId}`}
                  />
                  :
                </span>
                <span>{value}</span>
              </div>
            ))}
          </Col>
          <Col span={12}>
            {quoteInfo2?.map(({ labelId, value }) => (
              <div key={labelId}>
                <span>
                  <FormattedMessage
                    id={`book.quote.quoteBreakdown.${labelId}`}
                  />
                  {value && ':'}
                </span>
                {value && <span>{value}</span>}
              </div>
            ))}
          </Col>
        </Row>
        <Row
          gutter={[20, 10]}
          className="ViewQuoteBreakdownDialog-ItemizationSection"
        >
          <Col span={12}>
            <h2>
              <FormattedMessage id="book.quote.quoteBreakdown.quoteItemization" />
            </h2>
            {quote?.amounts?.map(({ desc, value }) => (
              <div key={desc}>
                <span>{desc}</span>
                <span>
                  <CurrencyFormat value={value} minimumFractionDigits={2} />
                </span>
              </div>
            ))}
          </Col>
          <Col span={12}>
            <h2>
              <FormattedMessage id="book.quote.quoteBreakdown.notIncluded" />
            </h2>
            {notIncludedItems?.map(item => (
              <p key={item}>
                <FormattedMessage id={`book.quote.quoteBreakdown.${item}`} />
              </p>
            ))}
          </Col>
        </Row>
        <div className="ViewQuoteBreakdownDialog-NoticeSection">
          <p>
            <FormattedMessage id="book.quote.quoteBreakdown.notice" />
          </p>
          <span>
            <FormattedMessage id="book.quote.quoteBreakdown.noticeMessage" />
          </span>
        </div>
        <Row
          gutter={[20, 10]}
          className="ViewQuoteBreakdownDialog-AddressSection"
        >
          {addresses?.map(({ labelId, address }) => {
            const {
              country,
              addressLine1,
              addressLine2,
              addressLine3,
              postalCode,
              city,
              stateProvince,
            } = address || {};
            return (
              <Col span={12} key={labelId}>
                <ConfigurableCard titleId={labelId}>
                  {[
                    country?.text,
                    compact([addressLine1, addressLine2, addressLine3]),
                    `${city?.text}, ${stateProvince?.text} ${postalCode}`,
                  ].map(text => (
                    <div key={text}>{text}</div>
                  ))}
                </ConfigurableCard>
              </Col>
            );
          })}
        </Row>
        <ConfigurableCard
          className="ViewQuoteBreakdownDialog-PiecesSection"
          titleId="book.quote.quoteBreakdown.piecesWeightSummary"
        >
          <Row className="ViewQuoteBreakdownDialog-PiecesSection__Preview">
            <Col span={8}>
              <span>
                <FormattedMessage id="book.newShipment.quantityWeightSummary.count" />
                :
              </span>
              <span>{quote?.pieces}</span>
            </Col>

            <Col span={8}>
              <span>
                <FormattedMessage id="book.newShipment.quantityWeightSummary.actualWeight" />
                :
              </span>
              <span>
                <WeightFormat
                  value={sum(quote?.packages?.map(p => p.actualWeight))}
                />
              </span>
            </Col>

            <Col span={8}>
              <span>
                <FormattedMessage id="book.newShipment.quantityWeightSummary.billedWeight" />
                :
              </span>
              <span>
                <WeightFormat value={quote?.weight} />
              </span>
            </Col>
          </Row>
          {quote?.packages?.map((piece, index) => (
            <QuotePackagesPreviewMode index={index} value={piece} viewMode />
          ))}
        </ConfigurableCard>
      </div>
    </Modal>
  );
}
