import { useCallback, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import IconButton from '../../components/buttons/IconButton';
import { InlineLink } from '../../components/typography';
import { QuoteBadge as Badge } from '../../app/enums/QuoteBadge';
import routes from '../../app/routes';
import { QuoteConversionDialog } from '../../dialogs/QuoteConversion';

export function QuoteBadge({ data }) {
  const [isConversionDialogOpen, setConversionDialogOpen] = useState(false);
  const { push } = useHistory();
  const { badge, referenceNo } = data || {};

  const openQuoteForm = useCallback(
    event => {
      event.stopPropagation();
      push(
        generatePath(routes.quoting.editQuote, {
          referenceNo,
        })
      );
    },
    [push, referenceNo]
  );

  const handleConversionOk = useCallback(() => {
    push(`/book/new-shipment/from-address?referenceNo=${referenceNo}`);
  }, [push, referenceNo]);

  const bookBtnClicked = event => {
    event.stopPropagation();
    setConversionDialogOpen(true);
  };

  const onBadgeClick = event => {
    event.stopPropagation();
  };

  return (
    <>
      <div className="quote-badge-section">
        <InlineLink onClick={openQuoteForm}>
          <FormattedMessage id="monitor.quote.reuse" />
        </InlineLink>
        {![Badge.INCOMPLETE, Badge.BOOK].includes(badge) ? (
          <span
            onClick={onBadgeClick}
            className={`badge badge-${Badge[badge]}`}
          >
            {Badge[badge]}
          </span>
        ) : (
          <IconButton icon="chevron-circle-right" onClick={bookBtnClicked}>
            <FormattedMessage id="monitor.quote.book" />
          </IconButton>
        )}
      </div>

      <QuoteConversionDialog
        isOpen={isConversionDialogOpen}
        onOk={handleConversionOk}
        onCancel={() => setConversionDialogOpen(false)}
      />
    </>
  );
}
