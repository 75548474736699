import React, { useEffect } from 'react';
import { Col, Row, message } from 'antd';
import { useQuery } from '@apollo/client';
import { first, get, last } from 'lodash-es';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { renderDataStateIndicator } from '../components/data/dataStateHandlers';
import { pxToNumber } from '../utils/cssUtils';
import { cssVariables } from '../styles/cssVariables';
import { QuoteSummaryHeader } from './quote/summary/QuoteSummaryHeader';
import { QuoteFlightItinerary } from './quote/summary/QuoteFlightItinerary';
import { QUOTE_DETAIL_QUERY } from '../app/graphql/quoteQueries';
import NoticeSection from './quote/summary/NoticeSection';
import ShipmentDetails from './quote/summary/ShipmentDetails';
import ModalOverlay from '../components/dialogs/ModalOverlay';
import ModalPage from '../templates/ModalPage';
import { UnitSystems } from '../app/enums/measuringUnits';
import FlightInformationSection from './quote/summary/components/FlightInformationSection';
import ContactInformation from './quote/summary/ContactInformation';
import { showQuoteExpirationMessage } from '../components/dialogs/QuoteRestrictionsMessage';
import QuoteDetailMap from './quote/summary/components/QuoteDetailMap';
import { OverrideSettingsContextProvider } from '../components/domainSpecific/settingsElements';

const FlightHeader = ({ flight }) => (
  <>
    <FormattedMessage id="quoteDetail.flight" />: {flight?.airline}{' '}
    {flight?.flightNumber}
  </>
);

const QuoteResultPage = () => {
  const location = useLocation();
  const { referenceNo } = useParams();
  const { formatMessage } = useIntl();
  const { data, loading, error } = useQuery(QUOTE_DETAIL_QUERY, {
    variables: { referenceNo },
    skip: !referenceNo,
  });
  const indicator = renderDataStateIndicator({ data, loading, error });
  const quoteDetail = data?.getCustomerQuote || {};

  const firstFlight = first(quoteDetail.flights);
  const lastFlight = last(quoteDetail.flights);
  const quoteDistanceUnit = get(
    UnitSystems[quoteDetail.unitSystem],
    'distance'
  );

  useEffect(() => {
    let key;
    if (location?.state?.newQuote && quoteDetail?.expiryDateTime) {
      key = showQuoteExpirationMessage(
        formatMessage,
        moment(quoteDetail?.expiryDateTime)
      );
    }
    return () => message.destroy(key);
  }, [quoteDetail?.expiryDateTime, location?.state?.newQuote, formatMessage]);

  return (
    <ModalOverlay>
      <ModalPage
        id="QuoteResultPage"
        className="ReleaseShipmentPage height-extending-error"
        wrapInScrollbar
      >
        <OverrideSettingsContextProvider unitSystem={quoteDetail.unitSystem}>
          {indicator || (
            <>
              <QuoteSummaryHeader
                referenceNo={referenceNo}
                quote={quoteDetail}
              />
              <Row
                gutter={[
                  pxToNumber(cssVariables.spaceNorm2),
                  pxToNumber(cssVariables.spaceNorm2),
                ]}
              >
                <ShipmentDetails
                  title="shipmentDetail.pickupLocation"
                  subtitle="book.quote.flightInformation.ready"
                  dateTime={quoteDetail.pickupDateTime?.scheduled}
                >
                  <FlightInformationSection
                    sectionTitle="book.newQuote.fromPickUp"
                    dateTime={quoteDetail.pickupDateTime?.scheduled}
                    highlightedText={quoteDetail.origin?.companyName}
                    address={quoteDetail.origin?.address}
                    badgeLabel="RDY"
                    distance={quoteDetail.origin?.airportDistance}
                  />
                  <FlightInformationSection
                    sectionTitle="book.newQuote.toDeliver"
                    dateTime={firstFlight?.lockoutDateTime}
                    highlightedText={<FlightHeader flight={firstFlight} />}
                    flight={firstFlight}
                    badgeLabel="L/O"
                    flightRows={[
                      {
                        label: 'book.newQuote.departs',
                        value: firstFlight?.departure?.estimated,
                      },
                    ]}
                  />
                  <ContactInformation contact={quoteDetail.origin?.contact} />
                </ShipmentDetails>

                <Col span={12} className="flight-itinerary">
                  <div style={{ marginBottom: '16px' }}>
                    <QuoteFlightItinerary flights={quoteDetail.flights} />
                  </div>
                  <QuoteDetailMap quote={quoteDetail} />
                  <NoticeSection />
                </Col>

                <ShipmentDetails
                  title="shipmentDetail.deliveryLocation"
                  subtitle="book.quote.flightInformation.estimatedDelivery"
                  dateTime={quoteDetail?.deliveryDateTime?.scheduled}
                >
                  <FlightInformationSection
                    sectionTitle="book.newQuote.fromPickUp"
                    // dateTime={lastFlight?.lockoutDateTime}
                    highlightedText={<FlightHeader flight={lastFlight} />}
                    badgeLabel="RcvETA"
                    distance={quoteDetail.destination?.airportDistance}
                    flightRows={[
                      {
                        label: 'quoteDetail.flightArival',
                        value: lastFlight?.arrival?.estimated,
                      },
                    ]}
                  />
                  <FlightInformationSection
                    sectionTitle="book.newQuote.toDeliver"
                    dateTime={quoteDetail.deliveryDateTime?.scheduled}
                    highlightedText={quoteDetail.destination?.companyName}
                    address={quoteDetail.destination?.address}
                    badgeLabel="DDL"
                    distanceUnit={quoteDistanceUnit}
                  />
                  <ContactInformation
                    contact={quoteDetail.destination?.contact}
                  />
                </ShipmentDetails>
              </Row>
            </>
          )}
        </OverrideSettingsContextProvider>
      </ModalPage>
    </ModalOverlay>
  );
};

export default QuoteResultPage;
