import { Col, Row } from 'antd';
import classNames from 'classnames';
import { isEqual, startsWith } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Prompt,
  matchPath,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import { useQuery } from '@apollo/client';

import routes from '../app/routes';
import Whitespace from '../components/Whitespace';
import { DataStateIndicatorGuard } from '../components/data/dataStateHandlers';
import { DialogsParentContext } from '../components/dialogs/DialogsParentContext';
import { OverrideSettingsContextProvider } from '../components/domainSpecific/settingsElements';
import { FormSanitizationProvider } from '../components/forms/FormSanitizationContext';
import { DynamicFormDependenciesProvider } from '../components/forms/dynamic/dynamicFormDependencies';
import { FormInterceptorContext } from '../components/forms/forms';
import { withPageGuard } from '../components/guards/PageGuard';
import { newShipmentGuard } from '../components/guards/pageGuards';
import { InnerContentScrollbars } from '../components/layout/InnerContentScrollbars';
import { FlexCol, TitleWithExtra } from '../components/layout/layoutElements';
import {
  StepsWithContent,
  isFirstStep,
  isLastStep,
} from '../components/nav/StepsWithContent';
import {
  AccountSelectionDialog,
  NewShipmentSubmitDialog,
  useNewShipmentSubmit,
} from '../dialogs/shipments/newShipmentDialogs';
import NewShipmentFormSchemaProvider from '../forms/shipments/newShipment/NewShipmentFormSchemaProvider';
import { SavedShipmentProvider } from '../forms/shipments/newShipment/SavedShipmentProvider';
import { NEW_SHIPMENT_STEPS } from '../forms/shipments/newShipment/newShipmentConstants';
import {
  CancelShipmentLink,
  NewShipmentBottomControls,
  NewShipmentFormButtons,
} from '../forms/shipments/newShipment/newShipmentElements';
import {
  isSavedShipmentModeActive,
  shouldForceTbdServiceType,
} from '../forms/shipments/newShipment/savedShipmentsUtils';
import useNewShipmentFormState from '../forms/shipments/newShipment/useNewShipmentFormState';
import useNewShipmentNiceUrl, {
  NewShipmentSubRoute,
} from '../forms/shipments/newShipment/useNewShipmentNiceUrl';
import useNewShipmentStateUpdaters from '../forms/shipments/newShipment/useNewShipmentStateUpdaters';
import useNewShipmentSteps from '../forms/shipments/newShipment/useNewShipmentSteps';
import useSubmitNewShipmentStep from '../forms/shipments/newShipment/useSubmitNewShipmentStep';
import { useShipmentTemplateChangeDetectionInterceptors } from '../forms/shipments/shipmentTemplates/ShipmentTemplateChangedDialog';
import ShipmentTemplatesCard from '../forms/shipments/shipmentTemplates/ShipmentTemplatesCard';
import { useAccount, useAccounts } from '../hooks/data/auth';
import { cssVariables } from '../styles/cssVariables';
import BaseLoggedPage from '../templates/BaseLoggedPage';
import { pxToNumber } from '../utils/cssUtils';
import ShipmentOrderPreviewCard from '../widgets/shipments/ShipmentOrderPreviewCard';
import { PageExitDialogType } from './nav/PageExitDialog';
import { shipmentConversions } from '../app/data/shipmentConversions';
import { QUOTE_DETAIL_QUERY } from '../app/graphql/quoteQueries';
import ActiveAccountHeader from '../widgets/ActiveAccountHeader';

function useQuoteFormValues({
  forms,
  account,
  setAccount,
  setUnitSystem,
  refreshValues,
}) {
  const { search } = useLocation();

  // Capture the prefilled quote data on the first render
  const queryParams = new URLSearchParams(search);
  const referenceNo = queryParams.get('referenceNo');
  const { data, loading } = useQuery(QUOTE_DETAIL_QUERY, {
    variables: { referenceNo },
    skip: !referenceNo && !!account,
  });
  const prefillQuote = data?.getCustomerQuote;
  const qouteAccount = useAccount(prefillQuote?.metadata?.qolAccount?.number);

  useEffect(() => {
    if (!prefillQuote || !account || loading || !forms[0].formRef.current) {
      return;
    }
    const newValues = shipmentConversions.quoteToShipmentForm(prefillQuote);
    const formValue = forms[0].form.getFieldsValue();
    const isOriginEqual = isEqual(
      newValues.origin?.address,
      formValue?.address
    );
    if (!isOriginEqual) {
      forms[0].form.setFieldsValue(newValues.origin);
      forms[1].form.setFieldsValue(newValues.destination);
      forms[2].form.setFieldsValue(newValues.serviceInformation);
      forms[3].form.setFieldsValue(newValues.packageList);
      forms.forEach(({ formRef }) => {
        if (formRef.current) {
          formRef.current.forceUpdate();
        }
      });
      setUnitSystem(newValues.unitSystem);
      refreshValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefillQuote, account, loading, forms]);

  useEffect(() => {
    if (!qouteAccount) return;
    setAccount(qouteAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qouteAccount]);

  return { referenceNo, quoteData: prefillQuote };
}

function NewShipmentPageWrapper({ account, title, children }) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const computedIsDialogOpen = isDialogOpen || !account;
  const isPartnerAccess = account?.partner;

  return (
    <BaseLoggedPage
      id="NewShipmentPage"
      leftSideContent={<ActiveAccountHeader account={account} />}
      className={classNames('height-extending-error', {
        'has-dialog': computedIsDialogOpen,
      })}
    >
      <DialogsParentContext.Provider
        value={{ isDialogOpen: computedIsDialogOpen, setDialogOpen }}
      >
        <FlexCol className="Flex1">
          {isPartnerAccess && (
            <div className="NewShipmentPage__PartnerAccessTopBar">
              <FormattedMessage id="book.newShipment.partnerAccess" />
              <Whitespace />|<Whitespace />
              {account.name}
              <Whitespace />
              {account.number}
            </div>
          )}
          {title}
          <InnerContentScrollbars>{children}</InnerContentScrollbars>
        </FlexCol>
      </DialogsParentContext.Provider>
    </BaseLoggedPage>
  );
}

function NewShipmentExitPrompt({ match }) {
  const { location } = useHistory();
  return (
    <Prompt
      when={
        // Don't open prompt when in account selection - there is nothing to be lost yet
        `${match.path}/${NewShipmentSubRoute.ACCOUNT_SELECTION}` !==
        location.pathname
      }
      message={({ pathname }) =>
        startsWith(pathname, routes.book.newShipment) || // New shipment subpages are allowed
        matchPath(pathname, { path: routes.shipmentDetail }) // Redirect to detail in the end is allowed
          ? true
          : PageExitDialogType.CANCEL_SHIPMENT
      }
    />
  );
}

function NewShipmentProviders({
  values,
  account,
  setAccount,
  unitSystem,
  shipmentTemplateChangeDetection,
  children,
}) {
  const accountsResult = useAccounts();

  const savedShipmentModeActive = isSavedShipmentModeActive(values);
  const forceTbdServiceType = shouldForceTbdServiceType(values);

  return (
    <FormInterceptorContext.Provider
      value={{
        onBlur: shipmentTemplateChangeDetection.onBlur,
        onKeyDown: shipmentTemplateChangeDetection.onKeyDown,
      }}
    >
      <NewShipmentFormSchemaProvider account={account}>
        <DynamicFormDependenciesProvider values={values}>
          <SavedShipmentProvider
            active={savedShipmentModeActive}
            forceTbdServiceType={forceTbdServiceType}
          >
            <OverrideSettingsContextProvider unitSystem={unitSystem}>
              <FormSanitizationProvider>
                <DataStateIndicatorGuard
                  queryResult={{
                    ...accountsResult,
                    data: accountsResult.customerAccounts,
                  }}
                >
                  {customerAccounts => (
                    <>
                      {children}
                      <AccountSelectionDialog
                        value={account}
                        onChange={setAccount}
                        customerAccounts={customerAccounts}
                      />
                    </>
                  )}
                </DataStateIndicatorGuard>
              </FormSanitizationProvider>
            </OverrideSettingsContextProvider>
          </SavedShipmentProvider>
        </DynamicFormDependenciesProvider>
      </NewShipmentFormSchemaProvider>
    </FormInterceptorContext.Provider>
  );
}

function NewShipmentPageInner() {
  const match = useRouteMatch();

  const {
    forms,
    refreshValues,
    account,
    setAccount,
    unitSystem,
    setUnitSystem,
    values,
    extractValues,
  } = useNewShipmentFormState();

  const [activeTemplateCounter, setActiveTemplateCounter] = useState(0);
  const { stepsProps, currentIndex, goToNext, goToPrev, step, resetStep } =
    useNewShipmentSteps({
      forms,
      refreshValues,
      activeTemplateCounter,
      steps: NEW_SHIPMENT_STEPS,
      disableUnvisitedSteps: true,
    });

  const { afterApplyTemplate } = useNewShipmentStateUpdaters({
    forms,
    refreshValues,
    setUnitSystem,
  });
  const afterCancelTemplate = useCallback(() => {
    afterApplyTemplate();
    resetStep();
  }, [afterApplyTemplate, resetStep]);
  const onActiveTemplateChange = useCallback(({ fromForm }) => {
    // If form is saved as a template, it's not considered a change of active template
    if (!fromForm) {
      setActiveTemplateCounter(old => old + 1);
    }
  }, []);

  const savedShipmentModeActive = isSavedShipmentModeActive(values);

  const { referenceNo } = useQuoteFormValues({
    forms,
    account,
    refreshValues,
    setAccount,
    setUnitSystem,
  });

  const {
    props: shipmentTemplateChangeDetectionProps,
    ...shipmentTemplateChangeDetection
  } = useShipmentTemplateChangeDetectionInterceptors();

  const {
    submit,
    dialogProps: submitDialogProps,
    isConfirming,
  } = useNewShipmentSubmit({ referenceNo });

  const submitStep = useSubmitNewShipmentStep({
    forms,
    stepsProps,
    currentIndex,
    submit,
    extractValues,
    goToNext,
    steps: NEW_SHIPMENT_STEPS,
  });

  useNewShipmentNiceUrl({
    baseUrl: match.url,
    account,
    isConfirming,
    step,
  });

  return (
    <NewShipmentProviders
      account={account}
      setAccount={setAccount}
      unitSystem={unitSystem}
      values={values}
      shipmentTemplateChangeDetection={shipmentTemplateChangeDetection}
    >
      <NewShipmentExitPrompt match={match} />
      <NewShipmentPageWrapper
        account={account}
        title={
          <TitleWithExtra
            title={
              <>
                <FormattedMessage id="book.newShipment.title" />
                <CancelShipmentLink className="hide-md-and-smaller" />
              </>
            }
            rightColClassName="hide-md-and-smaller"
          >
            <div className="NewShipmentTopControls">
              <NewShipmentFormButtons
                onSubmit={submitStep}
                onBack={goToPrev}
                isFirstStep={isFirstStep(stepsProps)}
                isLastStep={isLastStep(stepsProps)}
              />
            </div>
          </TitleWithExtra>
        }
      >
        <Row
          className="flex-nowrap"
          gutter={pxToNumber(cssVariables.spaceNorm2)}
        >
          <Col className="Flex1 spaces-vert-norm1_5">
            <ShipmentTemplatesCard
              forms={forms}
              values={values}
              afterApply={afterApplyTemplate}
              afterCancel={afterCancelTemplate}
              changeDetectionProps={shipmentTemplateChangeDetectionProps}
              onActiveItemChange={onActiveTemplateChange}
            />
            <StepsWithContent
              {...stepsProps}
              className="spaces-vert-norm1_5"
              stepsClassName="labels-in-middle"
              segmentedControlClassName="margin-hor-norm"
              clickable
            />
          </Col>
          <Col className="hide-md-and-smaller">
            <ShipmentOrderPreviewCard
              key={activeTemplateCounter}
              account={account}
              values={values}
              stepIndex={currentIndex}
            />
          </Col>
        </Row>
        <NewShipmentBottomControls
          submitStep={submitStep}
          stepsProps={stepsProps}
        />
        <NewShipmentSubmitDialog
          {...submitDialogProps}
          confirmData={submitDialogProps.confirmData}
          savedShipmentModeActive={savedShipmentModeActive}
        />
      </NewShipmentPageWrapper>
    </NewShipmentProviders>
  );
}

const NewShipmentPage = withPageGuard(newShipmentGuard)(NewShipmentPageInner);
export default NewShipmentPage;
