import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import {
  DateFormatMedium,
  TimeFormatShort,
} from '../../../utils/dateFormatting';

export default function DateTimeDisplay({
  label,
  dateTime,
  formatComponent: FormatComponent = DateFormatMedium,
  dayStyle,
  timeStyle,
  timeClass,
}) {
  const formattedDate = moment(dateTime);

  return (
    <span>
      {label && <FormattedMessage id={label} />}
      <span style={dayStyle}>
        <FormatComponent value={formattedDate} />
        {' | '}
      </span>
      <span style={timeStyle} className={timeClass}>
        <TimeFormatShort value={formattedDate} />
      </span>
    </span>
  );
}
